import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'

//applyMiddleware 是开启异步处理的中间件
import { createStore, applyMiddleware } from 'redux'
import reducers from './redux/redux'
import thunk from 'redux-thunk'
//使用react-redux来链接管理数据  不然使用store组件件传递太麻烦
import { Provider } from 'react-redux'
import './i18n'

import Home from './pages/home/index.jsx'


const store = createStore(reducers, applyMiddleware(thunk))
const RootDom = document.getElementById('root')
const root = createRoot(RootDom)

root.render(<Provider store = { store } >
    <Home />
</Provider>)
