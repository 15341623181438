import { Pagination,Spin,Empty,Button } from 'antd';
import { Popup, Toast,Dialog,Space,ErrorBlock } from 'antd-mobile'
import React from 'react'
import axios from 'axios'
import app from '../../pages/home/src/app.js'
import { withTranslation } from "react-i18next"
import { collectList,collect,collectCancel,collectType,collectEmpty,isPc} from "../../pages/home/config.js";
import ActionItem from '../actionItem/actionItem.js';

import './collect.less'

class Collect extends React.Component {

    state = {
        hasLogin: true,
        showLogin:false,
        showPanel:false,
        collectList:[],
        page:1,
        pageSize:isPc?12:9,
        empty:false,
        axiosAllData:null,
    }

    componentDidMount() { 
        app.collectComponent = this
        app.addCollect = (actionCode,cb,error)=>{
            if(app.token === undefined){ return }
            console.log(actionCode)
            axios.post(collect,{"actionCode":actionCode,"type":collectType})
            .then((response) => {
                if(response.data.code === "OK"){
                    Toast.show({ content:app.t('collect.collectSuccess')})
                    return cb()
                }else{
                    Toast.show({ content:app.t('collect.collectFailed')})
                    return error()
                }
            }).catch(function (err) {
                Toast.show({ content:app.t('collect.collectFailed')})
                    return error(error)
            })
        }
        app.removeCollect = (actionCode,cb,error)=>{
            if(app.token === undefined){ return }
            axios.post(collectCancel,{"codes": actionCode}).then((response) => {
                if(response.data.code === "OK"){
                    Toast.show({ content:app.t('collect.removeCollectSuccess')})
                    return  cb()
                }else{
                    Toast.show({ content:app.t('collect.removeCollectFailed')})
                    return  error()
                }
            }).catch(function (err) {
                Toast.show({ content:app.t('collect.removeCollectFailed')})
                return  error()
            })
        }
    }

    handleShowPanel =()=>{
        this.setState({
            showPanel:true
        })
    }
    onClose = () => {
        this.setState({
            showPanel:false
        })
    }
    
    //刚点进来的时候为0
    resetCollect=()=>{
        this.setState({
            page:1,
            axiosAllData:null
        })

        setTimeout(() => {
            this.updateCollect()
        })
    }

    updateCollect=()=>{
        const {page,pageSize} = this.state
        const _this = this
        // if(app.token === undefined){ return }
        axios.get(collectList+`?page=${ page }&size=${pageSize}`).then((response) => {
            console.log(response)
            if(response.data.code === "OK"){
                if(response.data.data.list.length>0){
                    _this.setState({
                        axiosAllData:response.data.data
                    })
                }else{
                    _this.setState({
                        axiosAllData:null,
                        empty:true
                    })
                }
            }else{
                Toast.show({ content:response.data.code})
                _this.setState({
                    axiosAllData:null,
                    empty:true
                })
                console.log(response.data.code)
            }
        }).catch(error=>{
            console.log(error)
        })
    }

    paginationChange=(page,pageSize)=>{
        const {axiosAllData}  = this.state
        axiosAllData.list = []
        this.setState({
            page:page,
            pageSize:pageSize,
            axiosAllData:axiosAllData
        })
        setTimeout(() => {
            this.updateCollect()
        })
    }

    emptyCollect=()=>{
        const {t}=this.props
        const _this = this
        Dialog.confirm({
            title: t('collect.clearHistory'),
            bodyStyle:{'zIndex':'999'},
            cancelText:'No',
            confirmText:'Yes',
            content: t('collect.clearAllRecords'),
            onConfirm:()=>{
                Dialog.clear()
                _this.fetchEmpty()
            },
            onCancel:()=>{console.log('onCancel')}
        })
    }
    fetchEmpty=()=>{
        const {t}=this.props
        axios.post(collectEmpty,{"type": collectType}).then((response) => {
            console.log(response)
            if(response.data.code === "OK"){
                Toast.show({ content:t('history.clearSuccess')})
            }else{
                // Toast.show({ content:response.data.code})
                Toast.show({ content:t('history.clearFailed')})
            }
            this.setState({showPanel:false})
        }).catch(error=>{
            console.log(error)
            Toast.show({ content:t('history.clearFailed')})
        })
    }

    render() {
        const {t} = this.props
        const { showPanel,axiosAllData,pageSize,empty} = this.state
        return (
                <Popup
                    // title={t('collect.myCollect')}
                    visible={showPanel}
                    // showCloseButton
                    onMaskClick={() => {
                        this.setState({showPanel:false})
                    }}
                    maskStyle={{
                        background:'rgba(0,0,0,0)',
                        touchAction: 'none',
                    }}
                    // position={isPc?'left':'bottom'}
                    bodyStyle={{
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        minHeight: isPc?'100vh':'85vh',
                        maxWidth:'500px',
                    }}
                >
                    <div className='collect-contain-wrap'>   
                       <div className='collect-top-bt-wrap'>
                            <span className='collect-title'>
                                {t('collect.myCollect')}
                            </span>
                            <Space style={{'float':'right','--gap':'12px'}}>
                                {
                                    empty===false?<Button
                                        onClick={this.emptyCollect}
                                        fill='none'>
                                        {t('common.clear')}
                                    </Button>:null
                                }
                                
                                <Button color='primary'
                                    onClick={()=>{this.setState({showPanel:false})}}
                                    fill='none'>
                                    {t('common.cancel')}
                                </Button>
                            </Space>

                       </div>
             
                        <div className='collectList-wrap'>
                            <div className='filter-contain-wrap'>
                                {
                                    axiosAllData&&axiosAllData.list.length?axiosAllData.list.map((item,index)=>{
                                        let percent
                                        if(window.innerWidth>500){
                                            percent = 25
                                        }else{
                                            let num = parseInt(window.innerWidth/120)
                                            percent= parseFloat(100/num).toFixed(2)
                                        }
                                   
                                        return <ActionItem
                                            widthStr={`calc(${percent}% - 10px)`}
                                            data={item}
                                            cb={this.onClose}
                                            key={index}/>
                                    }):<div className="filter-contain-wrap-loading">{
                                    empty?<ErrorBlock status='empty' description={''} title={t('common.noRecord')}>
                                            <Button color='primary' onClick={()=>{this.setState({showPanel:false})}}>
                                                {t('history.toExplore')}</Button>
                                        </ErrorBlock>:<Spin/>}</div>
                                }
                            </div>

                            {
                                axiosAllData&&axiosAllData.totalRecord>pageSize?
                                    <div className='filter-pagination-wrap'><Pagination 
                                        pageSize = {axiosAllData.pageSize}
                                        current = {axiosAllData.pageIndex}
                                        onChange={this.paginationChange} 
                                        total={axiosAllData.totalRecord} />
                                    </div>
                                :null
                            }
                                
                        </div>
                    </div>
                </Popup>
            )
    }

    
}


export default withTranslation()(Collect)



