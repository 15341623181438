import app from './app.js'
import {GLTFLoader} from './dev/jsm/loaders/GLTFLoader.js'
import {DRACOLoader} from './dev/jsm/loaders/DRACOLoader.js'
import {isDev,isPc,is3dbody} from '../config.js'

const loader = new GLTFLoader()
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('static/gltf/draco/')
loader.setDRACOLoader(dracoLoader)
app.equip = {}
const equipStr = ['barbell','dumbbell','bodyweight']
const textureLoader = new THREE.TextureLoader()
const qixieMap = textureLoader.load('static/img/qixieColor.jpg')

const skeletonVisible = isDev?true:false

let allMesh = []
let allMat = []
app.loadMain = function (cb) {
    loadGltf()
    function loadGltf() {
        //loader.load(`static/obj/66/Bunions and Overlapping Toes.emg`,  gltf=> {
        loader.load(`static/obj/test`,  gltf=> {
            let time = new Date().getTime()
            gltf.scene.traverse( child=> {
                if (child.isMesh) {
                    child.visible = false
                    allMesh.push(child)
                    child.frustumCulled = false
                    app.groupLinkToId[child.id] = child
                    cloneMultiMaterial(allMat, child)
                    app.allMeshReference[child.name] = child
                }else if(child.type==='Bone'){
                }
            })

            app.loadingComponent.handleLoadFinish()
            app.scene.add(gltf.scene)
            app.centerBone = app.scene.getObjectByName('Base_Hips')
            const initCenter  = app.scene.getObjectByName('Anal_sphincter_internal_L')
            initCenter&&(app.initCenter = app.calcMeshPosition(initCenter))

            handleAllmesh(allMesh)
            initAnimations(gltf,app.mixer)
            app.sliderComponent.handeLayerChange(0)

            setTimeout(() => {
                app.centerScene(()=>{
                    cloneMat(allMesh)
                    app.loadComplete = true
                    // reloadMapImg(allMat,()=>{
                    //     cloneMat(allMesh)
                    //     app.loadComplete = true
                    // })
                    app.loadingComponent.handleLoadFinish()
                    app.interactHelpComponent.initHasInteract()
                    if (typeof (cb) === 'function') {
                        return cb()
                    }
                })
            }, 100)

        }, xml=> {
            if (xml.loaded) {
                let cun = parseInt(xml.loaded / xml.total * 100)
                app.loadingComponent.updataLoading(cun)
                if(cun === 100){
                    app.loadingComponent.setState({
                        loadingFinished:true
                    })
                }
            }
        }, e=>{
            console.log(e)
        })
    }
}

function cloneMat(allMesh){
    allMesh.map(child => {
        cloneMultiMaterial(allMat, child,1)
    })
    allMesh.map(child => {
        let mat = child.material
        mat.originalColor = mat.color
        mat.originalEmissive = mat.originalColor
    })
}

//处理所有的加载结束的模型
function handleAllmesh(allMesh) {
    allMesh.map(child => {
        child.castShadow = true
        child.receiveShadow = true
        child.renderOrder = 2
        //child.visible = false
        let meshName = child.name
        //console.log(meshName)
        let mat = child.material
        child.originalMaterial = mat
        mat.flatShading = false
        mat.needsUpdate = true
        mat.transparent = true
        mat.metalness = 0
        mat.emissiveIntensity = 0
        mat.skinning = true
        mat.metalnessMap = null
        let Group = app.groupLinkToMesh[meshName] || null
        if(child.morphTargetDictionary){}

        if(meshName ==='M_Malelashes'){
            app.M_Malelashes = child
            mat.opacity = .9
            child.renderOrder = 20
        }

        if (Group) {
            Group.hasObj = true
            //如果有层级择需要保存起来
            if (Group.layer) {
                app.muscleLayerArr[Group.layer].push(child)
                child.layer = Group.layer
            }

            child.dataParent = Group
            Group.children.push(child)
            
            if (Group.rol === 'jr') {
                mat.roughness = .45
                mat.normalScale = new THREE.Vector2(1.5, -1.5)
                mat.morphTargets = true
                child.hasData = Group.hasData
                child.uuid = Group.uuid
                child.renderOrder = 40
                app.allMuscle.push(child)
                app.objects.push(child)

            } else if (Group.rol === 'gg') {
                child.renderOrder = 30
                mat.roughness = .4
                app.objects.push(child)
                mat.normalScale = new THREE.Vector2(1.1, -1.1)
                // if(mat.name.indexOf("bone_new")!==-1){
                //     mat.roughnessMap = boneRouMap
                //     mat.roughness = .75
                //     mat.needsUpdate = true
                // }
            } else if (Group.rol === 'sj') {
                mat.roughness = .2
                child.visible = false
                child.renderOrder = 3
                // mat.emissive=new THREE.Color(0xffd700)
                // mat.emissiveIntensity = 10
            }

            if(child.name.indexOf('Eye')!==-1){
                mat.opacity=.3
            }

        } else {
            if(child.name.indexOf('Bone')!==-1){
                child.visible = true
                child.renderOrder = 1
                mat.roughness = .4
                // app.objects.push(child)
                mat.normalScale = new THREE.Vector2(1.1, -1.1)
                console.log(child.name)
            }
            //child.visible = true
            if(child.name==='fixedObj'){
                app.fixedObj = child
                child.visible = false
            }
            //起止点
            if (child.name.indexOf('_zd') > child.name.length - 4) {
                child.visible = false
                // mat.depthTest = false
                child.renderOrder = 2
                mat.side = THREE.DoubleSide
                app.qizhidian.push(child)
                // child.scale.set(1,1,1)
            }
            if (child.name.indexOf('_qd') > child.name.length - 4) {
                child.visible = false
                // mat.depthTest = false
                child.renderOrder = 2
                mat.side = THREE.DoubleSide
                app.qizhidian.push(child)
                // child.scale.set(1,1,1)
            }
            //皮肤
            if (child.name === "skin_skin_M_Skin_L") {
                // mat.map = pifu
                //aa.alphaMap = alphaMap
                child.renderOrder = 8
                child.layer = 0
                mat.roughness = .6
                app.skinMesh = child

                child.visible = true
                //child.castShadow = true
                child.receiveShadow = true
                child.originalMap = child.material.map
                child.originelMat = mat
                mat.originalMap = mat.map
                app.opacityMat = mat.clone()
                app.opacityMat.map = undefined
                app.opacityMat.opacity  = .7
                app.opacityMat.depthWrite = false
                app.opacityMat.castShadow = false
                app.opacityMat.receiveShadow = false
                app.opacityMat.needsupdate = true
                //皮肤
                // app.opacityMat.onBeforeCompile = shader=>{
                //     console.log(323)
                //     console.log(shader)
                // }
            }
            //跟随场景加载的器械
            for (let i = 0; i < equipStr.length; i++) {
                const element = equipStr[i]
                if(child.name === element){
                    app.equip[element] = child
                    child.visible = false
                    child.material.opacity = .3
                    child.renderOrder = 19
                    child.material.transparent = true
                    child.material.needsupdate = true
                    child.material.map = qixieMap
                }
            }
            //脚底阴影
            if(child.name ==='ground'){
                child.material.opacity = .3
                child.renderOrder = 0
                //导出gif的时候地板不显示
                if(app.isExportGif){
                    child.visible = false
                }else{
                    child.visible = true
                }
            }
        }

    })
}

function reloadMapImg(allMat,cb){
    let index = 0
    let allLoad = []
    allMat.map(mat => {{
        if(mat.map) {
            allLoad.push({
                type:'map',
                mat:mat
            })
        }
        if(mat.normalMap){
            allLoad.push({
                type:'normalMap',
                mat:mat
            })
        }
    }})
    const len  = allLoad.length
    loadMap(allLoad[index])
    function loadMap(obj){
        let mat = obj.mat
        if(obj.type==='map'){
            let src = mat.map.source.data.currentSrc
            console.log(mat.map)
            console.log(mat.map.source)
            console.log(src)
            if(src){
                const boneRouMap = new THREE.TextureLoader().load( src.replaceAll('test.fbm/','test.fbm2K/'),(texture)=>{
                    texture.encoding = 3000
                    texture.flipY = false
                    texture.wrapT=1000
                    texture.wrapS=1000
                    texture.magFilter=1006
                    texture.minFilter=1008
                    texture.format = 1023
                    texture.colorSpace = "srgb"
                    mat.map = boneRouMap
                    mat.needsUpdate = true
                    index++
                    if(index===len){
                        cb()
                    }else{
                       return loadMap(allLoad[index])
                    }
                },undefined,( err )=>{
                    index++
                    if(index===len){cb()}else{return loadMap(allLoad[index])}
                })
            }

        }else{
            let src = mat.normalMap.source.data.currentSrc
            const boneNormalMap = new THREE.TextureLoader().load( src.replaceAll('test.fbm/','test.fbm2K/'),(texture)=>{
                texture.encoding = 3000
                texture.flipY = false
                texture.wrapT=1000
                texture.wrapS=1000
                texture.magFilter=1006
                texture.minFilter=1008
                texture.format = 1023
                texture.colorSpace = ""
                mat.normalMap = boneNormalMap;
                mat.needsUpdate = true
                index++
                if(index===len){cb()
                }else{
                    return loadMap(allLoad[index])
                }
            },undefined,( err )=>{
                index++
                if(index===len){
                    cb()
                }else{
                    return loadMap(allLoad[index])
                }
            })
        }
    }

}

app.loadSJQZD = (obj, type,cb, error)=>{
    if(app.pending) return
    app.pending = true
    let allMesh = []
    loader.load(`static/obj/${type}/`+obj.name, gltf=>{
        gltf.scene.traverse(function (child) {
            if (child.isMesh) {
                allMesh.push(child)
                child.frustumCulled = false
                app.groupLinkToId[child.id] = child
                cloneMultiMaterial(allMat, child,true)
                app.allMeshReference[child.name] = child
            }
        })
        obj[type] = true
        app.pending = false
        app.scene.add(gltf.scene)
        handleAllmesh(allMesh) 
        if (typeof (cb) === 'function') {
            return cb()
        }
    }, xml=> {
        //console.log(xml)
    }, e=>{
        console.log(e)
        app.pending = false
        if (error && typeof (error) === 'function') {
            return error(e)
        }
    })
}


app.loadActionFileFromNet = (path, cb, error) => {
    // loader.load(path, function (gltf) {
    loader.load(path+'?time='+new Date().getTime(), function (gltf) {
        initAnimations(gltf,app.mixer)
        if (typeof (cb) === 'function') {
            return cb()
        }
    },xml=> {
        //console.log(xml)
    },e=> {
        console.log(e)
        if (error && typeof (error) === 'function') {
            return error(e)
        }
    })
}

//播放动作
app.playActionByName = (obj) => {  
    let action = app.allAction[obj.fileName]
    if (!action) return
    console.log('正在播放的是：', action._clip)
    //不需要循环的动作  默认都是true THREE.LoopPingPong
    app.updatePlaying(obj)
    if(obj.isLoop===false){
        action.loop = THREE.LoopRepeat
    }
    //如果当前有动作正在播放
    if (app.playing) {
        //管他是重播还是新播  我都还原重来
        app.handleResetPrePlaying()
    }
    playNewAction()
    function playNewAction(){
        if (action === app.idleAction) {
            app.playIdle = true
        } else {
            app.playIdle = false
        }
        //如果有变形动画 
        if(obj.morphTargetDictionary&&obj.morphTargetDictionary.length){
            obj.morphTargetDictionary.map(item=>{
                item.obj = app.allMeshReference[item.obj_name]
            })
            app.playingMorphTargetDictionary = obj.morphTargetDictionary
        }else{
            app.playingMorphTargetDictionary = []
        }
        app.playing = action
        let speedNow = 1
        //速度跟progressComponent统一
        if(app.trackComponent){
            const {speed} = app.trackComponent.state
            speedNow = speed
            app.playing.timeScale = speed
        }
        app.playing
            .reset()
            // .setEffectiveTimeScale(1)
            .setEffectiveWeight(1)
            .fadeIn(.5)
            .play()

        //器械动作
        if(obj.equip){
            let equipAction = app.allAction[obj.equip.actionName]
            console.log(equipAction)
            if(obj.isLoop===false){
                equipAction.loop = THREE.LoopRepeat
            }
            if(equipAction){
                app.equipPlaying = equipAction
                app.equipPlaying.timeScale = speedNow
                app.equipPlaying        
                    .reset()
                    // .setEffectiveTimeScale(1)
                    .setEffectiveWeight(1)
                    .fadeIn(.5)
                    .play()
            }
        }
        if(obj.camera&&obj.camera.length){
            const {position,target} = obj.camera[0]
            let targetArr = target.split(',')
            let postionArr = position.split(',')

            new app.TWEEN.Tween(app.camera.position)
                .to({
                    x:postionArr[0],
                    y:postionArr[1],
                    z:postionArr[2],
                }, 1000)
                .easing(app.TWEEN.Easing.Linear.None)
                .start()
            new app.TWEEN.Tween(app.controls.target)
                .to({
                    x:targetArr[0],
                    y:targetArr[1],
                    z:targetArr[2],
                }, 1000)
                .easing(app.TWEEN.Easing.Linear.None)
                .start()
                .onUpdate(() => {
                    app.camera.lookAt(app.controls.target)
                })
        }else{
            if (app.centerBone) {
                new app.TWEEN.Tween(app.controls.target)
                .to({}, 1000)
                .easing(app.TWEEN.Easing.Linear.None)
                .start()
                .onUpdate(() => {
                    app.controls.target.x = app.centerBone.position.clone().x
                    app.controls.target.y = app.centerBone.position.clone().y
                    app.controls.target.z = app.centerBone.position.clone().z
                    app.camera.lookAt(app.controls.target)
                })
            }
        }
    }
}

//点击新的动作之前先还原上一个动作
app.handleResetPrePlaying =()=>{
    if(!app.playing) return
    app.playing.fadeOut(.5)
    app.playing = null
    //恢复器械的动作
    if(app.equipPlaying){
        app.equipPlaying.fadeOut(.5)
        app.equipPlaying = null
        // app.sceneEquip.clear()
    }
    //还原恢复变形
    // if(app.playingMorphTargetDictionary&&app.playingMorphTargetDictionary.length){
    //     app.playingMorphTargetDictionary.map(item=>{
    //         item.bp_arr.map(bp=>{
    //             item.obj.morphTargetInfluences[bp.bp_index] = 0
    //         })
    //     })
    //     app.playingMorphTargetDictionary = []
    // }
}

//停止动画
app.stopPlayAction = () => {
    if (!app.playing) return
    if(app.equipPlaying){
        app.sceneEquip.clear()
    }
    app.handleResetPrePlaying()
    app.updatePlaying(null)
    if (app.fixedObj) {
        app.fixedObj.visible = false
    }
    app.centerScene()
}


function cloneMultiMaterial(arr, obj,clone) {
    // 如果有模型使用同一材质  
    //如果一个模型有多个材质
    let flat = false
    let Mlen = arr.length
    //查看是否有模型使用同一材质
    for (var i = 0; i < Mlen; i++) {
        if (arr[i] === obj.material) {
            if(clone){
                obj.material = arr[i].clone()
                obj.material.needsUpdate = true
            }
            flat = true
            break
        }
    }
    if(!flat){
        arr.push(obj.material)
    }

}

app.ttt= []

function initAnimations(gltf,mixer){
    let animations = gltf.animations
    if (animations && animations.length) {
        for (let i = 0; i < animations.length; i++) {
            
            let clip = animations[i]
            let action = mixer.clipAction(clip)
            app.ttt.push(clip)
            action.clampWhenFinished = true //动作结束会回到初始位置   true是停留在最后一帧
            action.loop = THREE.LoopPingPong //（无限次重复）
            //action.loop = THREE.LoopPingPong  默认为 THREE.LoopRepeat（无限次重复）
            // action.play()
            if(mixer===app.mixer){
                if(!app.allAction[clip.name]){
                    app.allAction[clip.name] = action
                }
            }else{
                app.allAction[clip.name] = action
            }
        }
    }
    console.log(app.allAction)
}


//弹力带等器械需要单独加载  默认场景里面没有
app.loadEquipActionFileFromNet = (item,cb,error) => {
    let allMesh = []
    loader.load(item.filePath+'?time='+new Date().getTime(), gltf=> {
        app.sceneEquip.add(gltf.scene)
        app.mixer2 = new THREE.AnimationMixer(app.sceneEquip)
        //解析动画
        initAnimations(gltf,app.mixer2)
        gltf.scene.traverse(child=> {
            if (child.isMesh) {
                child.visible = true
                child.frustumCulled = false
                app.groupLinkToId[child.id] = child
                app.allMeshReference[child.name] = child
                if(child.name === item.modelName){
                    child.material.side = THREE.DoubleSide
                    app.equip[child.name] = child
                }
                child.material.needsUpdate = true
                child.renderOrder = 80
                child.material.transparent = true
               
                if(child.name.indexOf('bone')===-1){
                    child.material.opacity = .3
                    child.material.map = qixieMap
                }else{
                    child.material.roughness = .55
                }
                // allMesh.push(child)
            } 
        })
        // handleAllmesh(allMesh)
        if (typeof (cb) === 'function') {
            return cb()
        }
    }, xml=> {
    }, e=> {
        if (error && typeof (error) === 'function') {
            return error(e)
        }
    })
}

//加载本地动作文件
app.loadAnimationFile = (action, data, cb) => {
    loader.load(`static/obj/${action}?time=${new Date().getTime()}`, function (gltf) {
        // gltf.scene.traverse( child=> {
        //     if (child.isMesh) {
        //         allMesh.push(child)
        //         child.frustumCulled = false
        //     }else if(child.type==='Bone'){
        //     }
        // })
        // handleAllmesh(allMesh)
        // data.hasLoad = true
        app.sceneEquip.add(gltf.scene)
        app.mixer2 = new THREE.AnimationMixer(app.sceneEquip)
        //解析动画
        initAnimations(gltf,app.mixer2)

        if (cb && typeof (cb) === 'function') {
            return cb()
        }
    })
}


// for (let i = 0; i < jointList.length; i++) {
//     const element = jointList[i]
//     let joint = app.scene.getObjectByName(element)
//     if(!joint){
//         console.log(element+ '骨骼找不到')
//     }
// }




