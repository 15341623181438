import { ProgressBar } from 'antd-mobile'
import React from 'react'
import app from '../../pages/home/src/app.js'
import { withTranslation } from "react-i18next"
import './loadingBar.less'

class LoadingBar extends React.Component {

    state = {
        //是否显示进度条
        showloadingBar: true,
        //进度条数值
        loadingParcent: 0,
        //加载100%之后有一个载入内存过程，提醒初始化中
        loadingFinished: false,
    }

    updataLoading(num) {
        this.setState({
            loadingParcent: num
        })
    }

    handleLoadFinish = ()=> {
        window.appLoading.style.display = "none"
        this.setState({
            showloadingBar: false
        })
    }

    componentDidMount() {
        app.loadingComponent = this
    }
  
    render() {
        const {loadingParcent,showloadingBar,loadingFinished} = this.state
        const {t} = this.props

        return (
            <div className = {showloadingBar?'progress-wrap':'progress-wrap hide'}>
                <div className="progress-container">
                    <ProgressBar
                        strokeColor={'rgba(255, 192, 0, 1)'}
                        showInfo={false}
                        percent={ loadingParcent }/> 
                </div>
            </div>
        )
    }
}

export default withTranslation()(LoadingBar)