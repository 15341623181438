import React from "react"
import { Tabs} from "antd"
import app from "../../pages/home/src/app.js"

import { withTranslation } from "react-i18next"
import ActionItem from "../actionItem/actionItem.js"

import "./instrumenactionwrap.less"

class InstrumentActionWrap extends React.Component {
    state = {
        show: true,
        //器械
        instrumentIndex: "0",
        showInstrumentList: true,
        instrumentTypeList: null,
        activeKey: "0",
    };

    componentDidMount() {
        this.state.mainElementRef.addEventListener("scroll", this.handleScroll)
    }

    componentWillUnmount() {
        this.state.mainElementRef.removeEventListener("scroll", this.handleScroll)
    }

    handleScroll = () => {
        const { mainElementRef } = this.state
        const {instrumentTypeList} = this.props
        let currentKey = instrumentTypeList[0]
        for (const item of instrumentTypeList) {
            const element = document.getElementById(`anchor-${item}`)
            if (!element) continue
            const rect = element.getBoundingClientRect()
            let containWrapRect = mainElementRef.getBoundingClientRect()
            if (rect.top - containWrapRect.top <= 20) {
                currentKey = item
            } else {
                break
            }
        }
        this.setState({
            activeKey: currentKey,
        })
    }

    componentDidUpdate=(props)=>{}

    render() {
        const {
            activeKey,
            mainElementRef,
        } = this.state
        const { t,data,instrumentTypeList } = this.props

        return (
            <div className="instrument-action-wrap">
                <div className="instrument-action-wrap-inner">

                    <div
                        className="instrument-action-list-wrap"
                        ref={(el) => {
                            this.state.mainElementRef = el
                        }}
                    >
                        {instrumentTypeList &&
                            instrumentTypeList.length
                            ? instrumentTypeList.map((item, index) => (
                                <div key={index}>
                                    <h2 id={`anchor-${item}`}>{item}</h2>
                                    <ul className="action-list">
                                        {data &&
                                            data.length
                                            ? data.filter((action) => {
                                                    return action.instrumentType === item;
                                                })
                                                .map((item, index) => {
                                                    return (
                                                        <ActionItem
                                                            widthStr={"calc(33% - 15px)"}
                                                            data={item}
                                                            key={index}
                                                        />
                                                    );
                                                })
                                            : null}
                                    </ul>
                                </div>
                            ))
                            : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(InstrumentActionWrap);


