//import {  Slider } from 'antd-mobile';\
import React from 'react'
import app from '../../pages/home/src/app.js'
import {StarFilled} from '@ant-design/icons';

import { Image as IMAGE,Badge } from 'antd'

import { Button, Dialog} from 'antd-mobile'

import { updatePlaying } from "../../redux/redux.playing.js"
import { connect } from "react-redux"
import { errorPic,isAndroid,payLink,isPc} from '../../pages/home/config.js'
import { withTranslation } from "react-i18next"
import './actionItem.less'

@connect(
    // 你要state什么属性放到props里
    (state) => ({
        playing: state.PlayingReducer,
    }),
    // 你要什么方法，放到props里，自动dispatch
    { updatePlaying }
)
class ActionItem extends React.Component {
    state = {
        active:false,
        playing:false,
        collect:false,
        imgLoading:true
    }

    componentDidMount(){
        const { data } = this.props
        const _this = this
        if(data.thumbnailGIF){
            let img = new Image()
            img.src = data.thumbnailGIF
            img.onload=()=>{
                _this.setState({
                    imgLoading:false
                })
            }
            img.onerror=()=>{}
        }
    }
    
    actionClick = (item)=>{
        const {fromSearch,cb} = this.props
        if(item.isBuy!==true){
            // window.open(payLink)
            Dialog.confirm({
                title: 'Pricing',
                bodyStyle:{'zIndex':'999'},
                cancelText:'No',
                confirmText:'Yes',
                content: 'See plans?',
                onConfirm:this.confirm,
                onCancel:()=>{
                    console.log('onCancel')
                }
            })
        }else{
            if(fromSearch){
                app.searchComponent.hideComponent()
            }
            app.handleActionClick(item.code)
            if(cb){
                cb()
            }
        }
    }

    handleCollectClick=(e)=>{
        e.stopPropagation()
        const {data} = this.props
        console.log(data)
        if(data.isCollect){
            app.removeCollect(data.fileName,()=>{
                data.isCollect = false
                this.forceUpdate()
            })
        }else{
            app.addCollect(data.fileName,()=>{
                data.isCollect = true
                this.forceUpdate()
            })
        }
    }

    confirm = () =>{
        if(isPc){
            window.open(payLink)
        }else{
            //跳到对应的购买页面
            try {
                if (isAndroid) {
                    Android.toPurchase(1)
                } else {
                    window.webkit.messageHandlers.toPurchase.postMessage(1)
                }
            } catch (error) {
                console.log(error)
            }
        }
        app.iframeComponent.showReloadModal()
    }

    render() {
        const { data,playing,widthStr,t,searchStr} = this.props
        const {imgLoading} = this.state
        let playingNow = (playing&&playing.code===data.code)?true:false
        return (
            <div title={data.name} style={{'width':widthStr}} onClick={this.actionClick.bind(this, data)} className = {playingNow?'actioni-item-wrap active':'actioni-item-wrap'}>
                {
                    data.isComplete?<Badge.Ribbon text="done" className='Badge' content={t("common.new")}/>:null
                }
                <div className='action-item-img'>
                    <IMAGE
                        src= {imgLoading?errorPic:data.thumbnailGIF}
                        preview = {false}
                        fallback={errorPic}
                    /> 
                </div>
                {
                    data.isBuy!==true?<div className='lock-sign'></div>:null
                }
                <div className='action-item-name' dangerouslySetInnerHTML={{
                    __html:searchStr?data.name.toLowerCase().replaceAll(searchStr.toLowerCase(),'<span class=highlight>'+searchStr+'</span>'):data.name }}></div>
                {
                    data.isCollect? <div onClick={this.handleCollectClick} className='collect-bt-wrap'><StarFilled /></div>:null
                }  
            </div>
        )
    }
}

export default withTranslation()(ActionItem)


