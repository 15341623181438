
import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Pagination,Input,Spin,Button} from "antd"
import { Dropdown,Popup,Space,CheckList } from 'antd-mobile'
import { ArrowDownCircleOutline, DownOutline,UpOutline} from 'antd-mobile-icons'
import React from 'react'
import axios from 'axios'
import app from '../../pages/home/src/app.js'
import { connect } from 'react-redux'
import { withTranslation } from "react-i18next"
import {search,searchItem,isPc} from "../../pages/home/config.js"
import ActionItem from "../actionItem/actionItem.js"
import './search.less'

let onSearchTimer
let firstLoad = true
//判断时候是数字或者英文字母
const  numAndlettersRes = /[0-9a-z]/i

class searchComponent extends React.Component {

    state = {
        value:'',
        showSearchResult:false,
        //搜索到的肌肉
        searchMuscle:[],
        axiosAllData:null,
        loadingAxiosAllDat:false,
        
        //正在搜索
        searchLoading:false,
        moreMuscle:false,
        
        //历史记录相关
        moreHistory:false,
        historyList:[],

        //展示搜索框下面的内容
        showBottomPanel:false,
        showBottomPanelInner:false,
        
        //动作筛选相关
        hasInitSearchItem:false,
        loadingSearchItem:false,
        
        instrument:'',
        position:'',
        type:'',

        instrumentList:[],
        positionList:[],
        typeList:[],

        pagination:null,
        pageIndex:1,
        pageSize:isPc?12:9,

        showPanel:false

    }

    componentDidMount() { 
        const {hasInitSearchItem} = this.state
        app.searchComponent = this
        //初始化历史记录
        this.initHistory()
        //this.getData()
    }
    //神
    initSearchItem=()=>{
        const {hasInitSearchItem} = this.state
        if(!hasInitSearchItem){
            axios
            .get(searchItem)
            .then( response=>{
                if (response.data.code === "OK") {
                    const data = response.data.data
                    this.setState({
                        hasInitSearchItem:true,
                        instrumentList:data.instrumentType,
                        positionList:data.position,
                        typeList:data.type,
                    })
                }else{
                    this.setState({
                        hasInitSearchItem:false,
                    })
                }
            })
            .catch( error=>{
                this.setState({
                    hasInitSearchItem:false,
                })
            })
        }
    }


    startSearching=(e)=>{
        //初始化筛选项
        this.initSearchItem()

        app.panel.snapTo(1)
        app.muscleComponent.setPanel(false)
        //第一次搜索
        if(firstLoad){
            this.fliterChange()
        }
        
    }

    initHistory=()=>{
        let searchHistory = localStorage.getItem('searchHistory')
        if(searchHistory){
            searchHistory = JSON.parse(searchHistory)
        }
        if(searchHistory&&searchHistory.length){
            this.setState({
                historyList:searchHistory
            })
        }
    }

    hideComponent=()=>{
        this.setState({
            showPanel:false
        })
        this.Dropdown&&this.Dropdown.close()
    }

    clearHistory = ()=>{
        try {
            localStorage.removeItem('searchHistory')
        } catch (error) {
            console.log(error)
            return  
        }
        this.setState({
            historyList:[],
        })
    }


    restSearchActionData=()=>{
        this.setState({
            axiosAllData:null
        })
    }

    historyItemClick=(value)=>{
        this.onSearch(value)
        setTimeout(() => {
            this.state.input.focus()
        }, 200)
    }
 
    onSearch = (value)=>{
        let searchStr = value.target?value.target.defaultValue:value
        this.setState({value:searchStr})
        if(onSearchTimer) clearInterval(onSearchTimer)
        //防抖动
        onSearchTimer = setTimeout(() => {
            //去掉两边空格
            if(searchStr){
                searchStr=searchStr.trim()
            }
            //如果输入为空则显示筛选项与历史记录
            if(searchStr===''){
                this.initHistory()
                this.restSearchActionData()
                this.setState({
                    searchMuscle:[],
                    showSearchResult:false
                })
                this.getData()
                return
            }
            //单个=英文字母或数字则返回
            if(searchStr.length===1&&numAndlettersRes.test(searchStr)){
                this.setState({
                    axiosAllData:null,
                    searchLoading:false
                })
                return
            }

            this.setState({
                searchLoading:true,
            })

            //搜索场景里面的肌肉
            // let searchMuscle = []
            // console.log(searchStr)
            // for (let i = 0; i < app.allMuscle.length; i++) {
            //     const muscle = app.allMuscle[i]
            //     if(muscle.dataParent&&muscle.dataParent.name.indexOf(searchStr)!==-1){
            //         console.log(muscle.dataParent.name)
            //         searchMuscle.push(muscle)
            //     }
            // }
            // console.log(searchMuscle)
            // this.setState({
            //     moreMuscle:false,
            //     searchMuscle:searchMuscle,
            //     showSearchResult:true
            // })
            this.getData({keyword:searchStr})
        }, 200)

    }
   
    //注意 ： 接口参数是instrumentType，组件属性是instrument，  不要混淆！！！  不要问我为啥组件属性是instrument
    getData=(obj)=>{
        const _this= this
        const {value,pageSize} = this.state
        this.setState({
            searchLoading:true
        })
        //只要请求过置为false
        firstLoad = false

        let params = {keyword:value,page:1,size:pageSize,type:'',instrumentType:'',position:''} 
        //搜索的时候只有keyword一个选项
        if(obj){
            const {keyword,page,size} = obj
            keyword&&(params.keyword = keyword)
            page&&(params.page = page)
            size&&(params.size= size)
        }
        const {instrument,position,type} = this.state
        position&&(params.position = position)
        instrument&&(params.instrumentType = instrument)
        type&&(params.type= type)

        if(type!=='function'&&type!=='stretching'&&instrument){
            params.instrumentType = instrument
        }else{  params.instrumentType = "" }

        console.log(params)
        axios.get( search, { params: params}).then( (response)=> {
                if (response.data.code === "OK") {
                    const data = response.data.data
                    console.log(response)
                    this.setState({
                        axiosAllData:data.actionList,
                        searchMuscle:data.muscleList,
                        searchLoading:false,
                        showSearchResult: params.keyword?true:false
                    })
                }else{
                    this.setState({
                        axiosAllData:[],
                        searchMuscle:[],
                        searchLoading:false,
                        showSearchResult: params.keyword?true:false
                    })
                    this.restSearchActionData()
                }
            })
            .catch(function (error) {
                console.log(error)
                _this.setState({
                    searchLoading:false,
                    showSearchResult: params.keyword?true:false
                })
                _this.restSearchActionData()
            })
    }

    //动作带的肌肉的点击
    muscleClick=(id)=>{
        for (let i = 0; i < app.allMuscle.length; i++) {
            const obj = app.allMuscle[i]
            if(obj.uuid === parseInt(id)){
                obj.visible = true
                app.clickOption(obj)
                app.hideOriginsAndinsertionAndNerves()
                app.lookAtSelectObj([obj])
                app.searchComponent.hideComponent()
                break
            }
        }
    }
    
    //搜索出来的肌肉的点击
    searchMuscleClick=(item)=>{
        for (let i = 0; i < app.allMuscle.length; i++) {
            const obj = app.allMuscle[i];
            if(obj.uuid === item.id){
                obj.visible = true
                app.clickOption(obj)
                app.lookAtSelectObj([obj])
                app.hideOriginsAndinsertionAndNerves()
                app.searchComponent.hideComponent()
                break
            }
        }
    }



    clickMoreMuscle=()=>{
        this.setState({
            moreMuscle:!this.state.moreMuscle
        }) 
    }

    clickMoreHistory=()=>{
        this.setState({
            moreHistory:!this.state.moreHistory
        }) 
    }

    onCompositionEnd=(vule)=>{
        console.log('onCompositionEnd')
        let data 
        try {
            if(vule&&vule.data){
                data = vule.data
            }else{
                return
            }
            if(data===''||data==='肌'){
                return
            }
            let searchHistory = localStorage.getItem('searchHistory')
            //保存过记录
            if(searchHistory){
                searchHistory = JSON.parse(searchHistory)
                if(searchHistory.indexOf(data)===-1){
                    searchHistory.unshift(data)
                    localStorage.setItem('searchHistory',JSON.stringify(searchHistory))
                }
            //没有记录
            }else{
                searchHistory = [data]
                localStorage.setItem('searchHistory',JSON.stringify(searchHistory))
            }
        } catch (error) {
            console.log(error)
        }
    }

    fliterChange=()=>{
        setTimeout(() => {
            this.getData()
        }, 10)
    }

    paginationChange=(page)=>{
        const {axiosAllData,pageSize} = this.state
        axiosAllData.list = []
        this.setState({
            axiosAllData:axiosAllData
        })
        setTimeout(() => {
            this.getData({
                page:page,
                size:pageSize
            })
        }, 10)
    }

    showPanel =()=>{
        this.setState({
            showPanel:true
        })
    }
    onClose = () => {
        this.setState({
            showPanel:false
        })
    }

    render() {
        const {searching} = this.props
        const { 
            moreHistory,historyList,showPanel,
            showSearchResult,searchLoading,value,
            instrument,position,type,instrumentList,positionList,typeList,
            pageSize,axiosAllData,searchMuscle,moreMuscle} = this.state
        const historyLen = historyList.length
        const { t } = this.props

        return (
            <Popup
                // title={t('collect.myCollect')}
                visible={showPanel}
                // showCloseButton
                onMaskClick={() => {
                    this.setState({showPanel:false})
                    this.Dropdown.close()
                }}
                maskStyle={{
                    background:'rgba(0,0,0,0)',
                    touchAction: 'none',
                }}
                // position={'left'}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    minHeight: isPc?'100vh':'85vh',
                    maxWidth:'500px',
                }}>
                <div className='search-wrap' id='search-wrap'>
                    
                    <div className="search-bar-wrap">
                        <AutoComplete
                            className='AutoComplete-wrap'
                            ref={(el) => (this.state.input = el)} 
                            getPopupContainer={el => (el ? el.parentNode : document.body)}
                            value = {value}
                            onChange={this.onSearch}
                            onCompositionEnd={this.onCompositionEnd}>
                                
                            <Input.Search 
                                style={{
                                    color:'#000'
                                }}
                                size='large'
                                onFocus = {this.startSearching}
                                loading = {searchLoading?true:false} 
                                onPressEnter={this.onSearch}
                                allowClear
                                placeholder={t("search.searchTip")}
                                prefix={<SearchOutlined
                                    style={{
                                    fontSize: 16,
                                    color: '#999',
                                    }}
                                />}
                            />
                        </AutoComplete> 
                        <Button type="primary" onClick={()=>{
                            this.setState({showPanel:false})
                        }} className="searching-cancel-bt">
                            {t('common.cancel')}
                        </Button>
                    </div>
    
                    <div className='search-bottom-panel'>
                        <div className='search-top-full'>
                            <div className='max-width-1300px'>
                                {
                                    historyList&&historyLen?<div className='history-contain-wrap'>
                                        <div className='history-title'>
                                            {t("search.history")}
                                        </div>
                                        <div className='clear-history-wrap'>
                                            <a style={{float: 'right',}} onClick = {this.clearHistory} href="#javascript"
                                            rel="noopener noreferrer"> {t("common.clear")}</a>
                                        </div>
                                        
                                        <div className={moreHistory?'history-item-wrap moreHistory':'history-item-wrap'}>
                                            {
                                                historyList.map((item,index)=>{
                                                    return  <div key={index} onClick={this.historyItemClick.bind(this,item)} 
                                                                className='history-item'
                                                            type="text"> <span>{item}</span>
                                                        </div>
                                                })
                                            }
                                        </div>
                                    </div>:null
                                }

                                {
                                    showSearchResult?null:<div className='filter-wrap'>

                                        <Dropdown closeOnClickAway={true} ref={(ele=>this.Dropdown=ele)}>

                                            <Dropdown.Item key='bizoperr' activeKey={position} title={position?position:t("header.area")}>
                                                <div style={{ padding:'0 12' }}>
                                                    <CheckList onChange={(arr)=>{
                                                        console.log(arr)
                                                        this.setState({
                                                            position:arr[0]
                                                        })
                                                        this.Dropdown.close()
                                                        this.fliterChange()
                                                    }} value={[position]}>
                                                        <CheckList.Item value=''>{t("header.allArea")}</CheckList.Item>
                                                        {
                                                            positionList.length?positionList.map((item,index)=>{
                                                                return <CheckList.Item key={index} value={item.title}>
                                                                    <span><img style={{'height':'25px','width':'25px','float':'left',marginRight:'12px'}} src={ item.image}/><span>{ item.title}</span></span>
                                                                    </CheckList.Item>
                                                            }):null
                                                        }
                                                    </CheckList>
                                                </div>
                                            </Dropdown.Item>

                                            <Dropdown.Item key='bizop' title={type?type:t("header.motionType")}>
                                                <div style={{ padding:'0 12' }}>
                                                    <CheckList onChange={(arr)=>{
                                                        console.log(arr)
                                                        this.setState({
                                                            type:arr[0]
                                                        })
                                                        if(arr[0]!==''){
                                                            this.setState({
                                                                instrument:''
                                                            })
                                                        }
                                                        this.Dropdown.close()
                                                        this.fliterChange()
                                                    }} value={[type]}>
                                                        <CheckList.Item value=''>{t("header.allMotionType")}</CheckList.Item>
                                                        {
                                                            typeList.length?typeList.map((item,index)=>{
                                                                return <CheckList.Item key={index} value={item.title}>
                                                                    <span><img style={{'height':'25px','width':'25px','float':'left',marginRight:'12px'}} src={ item.image}/><span>{ item.title}</span></span>
                                                                    </CheckList.Item>
                                                            }):null
                                                        }
                                                    </CheckList>
                                                </div>
                                            </Dropdown.Item>

                                            <Dropdown.Item key='more' title={instrument?instrument:t("header.instrument")}>
                                                <div style={{ padding:'0 12' }}>
                                                    <CheckList disabled={type==='function'?true:false} onChange={(arr)=>{
                                                        console.log(arr)
                                                        this.setState({
                                                            instrument:arr[0]
                                                        })
                                                        this.Dropdown.close()
                                                        this.fliterChange()
                                                    }} value={[instrument]}>
                                                        <CheckList.Item value=''>{t("header.allInstrument")}</CheckList.Item>
                                                        {
                                                            instrumentList.length?instrumentList.map((item,index)=>{
                                                                return <CheckList.Item key={index} value={item.title}>
                                                                    <span><img style={{'height':'25px','width':'25px','float':'left',marginRight:'12px'}} src={ item.image}/><span>{ item.title}</span></span>
                                                                    </CheckList.Item>
                                                            }):null
                                                        }
                                                    </CheckList>
                                                </div>
                                    
                                            </Dropdown.Item>
                                        </Dropdown>

                                    </div>
                                }
                                
                                {
                                    searchMuscle&&searchMuscle.length?<div className='searchMuscle-wrap'>

                                        <div className='searchMuscle-item-title'>{t("search.searchMuscleResultTip")} {searchMuscle.length}</div>

                                        <div className={moreMuscle?'searchMuscle-item-wrap moreMuscle':'searchMuscle-item-wrap'}>
                                            {
                                                searchMuscle.map((item,index)=>{
                                                    return  <Button key={index} onClick={this.searchMuscleClick.bind(this,item)} 
                                                                className='searchMuscle-item'>
                                                                <span dangerouslySetInnerHTML={{
                                                            __html:value?item.name.toLowerCase().replaceAll(value.toLowerCase(),'<span class=highlight>'+value+'</span>'):item.name }}></span>
                                                        </Button>
                                                })
                                            }
                                        </div>
                                        {   
                                            searchMuscle.length>=10?<Button onClick={this.clickMoreMuscle} 
                                                className='searchMuscle-more-wrap'
                                                type="text"> {moreMuscle?t('search.fold'):t('search.unfold')}
                                                <span>{moreMuscle?<UpOutline />:<DownOutline/>}</span>
                                            </Button>:null
                                        }
                                    </div>:null
                                }
                            </div>
                        </div>

                        <div className='search-contain-full'>
                            <div className='filter-contain-wrap'>
                                {
                                    axiosAllData?<div className='find-action-total-title'>
                                        {t("search.searchMotionResultTip")} {axiosAllData.totalRecord}
                                    </div>:null
                                }
                                {
                                    axiosAllData&&axiosAllData.list.length?axiosAllData.list.map((item,index)=>{
                                        let percent
                                        if(window.innerWidth>500){
                                            percent = 25
                                        }else{
                                            let num = parseInt(window.innerWidth/120)
                                            percent= parseFloat(100/num).toFixed(2)
                                        }
                                        return <ActionItem
                                            searchStr={value}
                                            widthStr={`calc(${percent}% - 10px)`}
                                            data={item}
                                            cb={()=>{
                                                this.setState({showPanel:false})
                                            }}
                                            key={index}/>
                                    }):<div className="search-filter-contain-wrap-loading">{searchLoading?<Spin />:<span>{t('common.noResults')}</span>}</div>
                                }
                            </div>
                            {
                                axiosAllData&&axiosAllData.totalRecord>pageSize?
                                    <div className='filter-pagination-wrap'><Pagination 
                                        pageSize = {pageSize}
                                        current = {axiosAllData.pageIndex}
                                        onChange={this.paginationChange} 
                                        total={axiosAllData.totalRecord} />
                                    </div>
                                :null
                            }
                        </div>
                    </div>
    
                </div>
            </Popup>
            )
    }
    
}


export default withTranslation()(searchComponent)








// <div className='filter-type-item'>
// <div className='filter-title'>{t("header.area")} :</div>
// <div className='filter-item-list'>
//     <Button onClick={()=>{
//         this.setState({
//             position:''
//         })
//         this.fliterChange()
//     }} type='text'  className={position===''?'active':null}  value="">{t("header.allArea")}</Button>
//     {
//         positionList.length?positionList.map((item,index)=>{
//             return <Button onClick={()=>{
//                 this.setState({
//                     position:item.title
//                 })
//                 this.fliterChange()
//             }}  className={position===item.title?'active':null} type='text' key={index} value={item.title}>
//                 <Space><img style={{'height':'25px','width':'25px','float':'left'}} src={ item.image}/><span>{ item.title}</span></Space>
//             </Button>
//         }):null
//     }
// </div>
// </div>

// <div className='filter-type-item'>
// <div className='filter-title'>{t("header.motionType")} :</div>
// <div className='filter-item-list'>
//     <Button onClick={()=>{
//         this.setState({
//             type:''
//         })
//         this.fliterChange()
//     }} type='text'  className={type===''?'active':null}  value="">{t("header.allMotionType")}</Button>
//     {
//         typeList.map((item,index)=>{
//             return <Button key={index} type='text' onClick={()=>{
//                 this.setState({
//                     type:item.title
//                 })
//                 this.fliterChange()
//             }}  className={type===item.title?'active':null}  value={item.title}>
//                  <Space><img style={{'height':'25px','width':'25px','float':'left'}} src={ item.image}/><span>{ item.title}</span></Space>
//             </Button>
//         })
//     }
// </div>
// </div>

// <div className='filter-type-item'>
// <div className='filter-title'>{t("header.instrument")} :</div>
// <div className='filter-item-list'>
//     <Button type='text'  className={instrument===''?'active':null}   onClick={()=>{
//                 this.setState({
//                     instrument:''
//                 })
//                 this.fliterChange()
//             }}  value="">{t("header.allInstrument")}</Button>
//     {
//         instrumentList&&instrumentList.length?instrumentList.map((item,index)=>{
//             return <Button  className={instrument===item.title?'active':null}  onClick={()=>{
//                 this.setState({
//                     instrument:item.title
//                 })
//                 this.fliterChange()
//             }}  type='text' key={index} value={item.title}>
//                  <Space><img style={{'height':'25px','width':'25px','float':'left'}} src={ item.image}/><span>{ item.title}</span></Space>
//             </Button>
//         }):null
//     }
// </div>
// </div>



// <Dropdown ref={(ele=>this.Dropdown=ele)} onChange={(e)=>{
//     console.log(e)
//     }}>
//     <Dropdown.Item key='bizoper' activeKey={position} title={position?position:t("header.area")}>
//         <div style={{ padding: 12 }}>
//         <Button onClick={()=>{
//             this.setState({
//                 position:''
//             })
//             this.Dropdown.close()
//             this.fliterChange()
//         }} type='text'  className={position===''?'active':null}  value="">{t("header.allArea")}</Button>
//         {
//             positionList.length?positionList.map((item,index)=>{
//                 return <Button onClick={()=>{
//                     this.setState({
//                         position:item.title
//                     })
//                     this.Dropdown.close()
//                     this.fliterChange()
//                 }}  className={position===item.title?'active':null} type='text' key={index} value={item.title}>
//                     <Space><img style={{'height':'25px','width':'25px','float':'left'}} src={ item.image}/><span>{ item.title}</span></Space>
//                 </Button>
//             }):null
//         }
//         <CheckList defaultValue={['B']}>
//             <CheckList.Item value='A'>A</CheckList.Item>
//             {
//                 positionList.length?positionList.map((item,index)=>{
//                     return <CheckList.Item value={item.title}>
//                         <Space><img style={{'height':'25px','width':'25px','float':'left'}} src={ item.image}/><span>{ item.title}</span></Space>
//                         </CheckList.Item>
//                 }):null
//             }
//             </CheckList>
//         </div>
//     </Dropdown.Item>
//     <Dropdown.Item key='bizop' title={type?type:t("header.motionType")}>
//         <div style={{ padding: 12 }}>
//          <Button onClick={()=>{
//             this.setState({
//                 type:''
//             })
//             this.Dropdown.close()
//             this.fliterChange()
//         }} type='text'  className={type===''?'active':null}  value="">{t("header.allMotionType")}</Button>
//         {
//             typeList.map((item,index)=>{
//                 return <Button key={index} type='text' onClick={()=>{
//                     this.setState({
//                         type:item.title
//                     })
//                     this.Dropdown.close()
//                     this.fliterChange()
//                 }}  className={type===item.title?'active':null}  value={item.title}>
//                         <Space><img style={{'height':'25px','width':'25px','float':'left'}} src={ item.image}/><span>{ item.title}</span></Space>
//                 </Button>
//             })
//         }           
//         </div>
//     </Dropdown.Item>
//     <Dropdown.Item key='more' title={instrument?instrument:t("header.instrument")}>
//         <div style={{ padding: 12 }}>
//         <Button type='text'  className={instrument===''?'active':null}   onClick={()=>{
//                     this.setState({
//                         instrument:''
//                     })
//                     this.Dropdown.close()
//                     this.fliterChange()
//                 }}  value="">{t("header.allInstrument")}</Button>
//         {
//             instrumentList&&instrumentList.length?instrumentList.map((item,index)=>{
//                 return <Button  className={instrument===item.title?'active':null}  onClick={()=>{
//                     this.setState({
//                         instrument:item.title
//                     })
//                     this.Dropdown.close()
//                     this.fliterChange()
//                 }}  type='text' key={index} value={item.title}>
//                      <Space><img style={{'height':'25px','width':'25px','float':'left'}} src={ item.image}/><span>{ item.title}</span></Space>
//                 </Button>
//             }):null
//         }
//         </div>
//     </Dropdown.Item>
// </Dropdown>
