import React from "react"
import { Button,Space,Divider} from "antd-mobile"
import app from "../../../pages/home/src/app.js"
import { withTranslation } from "react-i18next"
import { LeftOutline } from 'antd-mobile-icons'
import "./qzd.less"

class Qzd extends React.Component {
    state = {
        //显示肌肉信息的高度
        show:false
    }

    componentDidMount() {
        app.qzdComponent = this
    }

    //显示起止点
    enableMesh = (flag) => {
        console.log(flag)
        console.log(app.selectMuscle)
        if (!app.selectMuscle) return 
        // app.progressComponent.closePanel()
        const handleOriginsAndinsertion =()=>{
            app.hideOriginsAndinsertionAndNerves()
            if (flag) {
                app.handleResetPrePlaying()
                app.resetAllTempOption()
                if(app.progressComponent){
                    app.progressComponent.setState({
                        show: false,
                    })
                }
                app.allMuscle.map((item) => {
                    item.visible = false
                    item.material.opacity = 1
                    item.renderOrder = 6
                })
                app.fixedObj && (app.fixedObj.visible = false)
                app.selectMuscle.visible = true
                app.selectMuscle.material.opacity = 0.3
                app.selectMuscle.renderOrder = 8
                let qzdFuzhu = app.selectMuscle.dataParent.qzdFuzhu
                console.log(qzdFuzhu)
                if(qzdFuzhu){
                    qzdFuzhu.split(',').forEach(item=>{
                        let obj = app.scene.getObjectByName(item)
                        console.log(item)
                        console.log(obj)
                        if(obj){
                            obj.visible = true
                            obj.material.opacity = .3
                        }
                    })
                }
                let name = app.selectMuscle.name
                let qidian = app.scene.getObjectByName(name + "_qd")
                let zhidian = app.scene.getObjectByName(name + "_zd")
                //定位视角用
                let group = []
                if (zhidian) {
                    zhidian.visible = true
                    app.zhidianObj = zhidian
                    group.push(zhidian)
                    app.visibleOriginsAndinsertionAndNerves.push(zhidian)
                }
                if (qidian) {
                    qidian.visible = true
                    app.qidianObj = qidian
                    group.push(qidian)
                    app.visibleOriginsAndinsertionAndNerves.push(qidian)
                }
                app.lookAtSelectObj(group)
            } else {
                app.sliderComponent.resetSlider()
                app.centerScene()
            }
        }
        if(app.selectMuscle.qzd){
            handleOriginsAndinsertion()
        }else{
            app.loadSJQZD(app.selectMuscle,'qzd',()=>{
                handleOriginsAndinsertion()
            }, ()=>{
                console.log(app.selectMuscle,'神经加载失败哎')
            })
        }
    }

    originClick=()=>{
        const {name} = app.selectMuscle
        let d2Mesh = app.allMeshReference[name+"_qd_d2"]
        let d1Mesh = app.allMeshReference[name+"_qd_d1"]
        app.d2LookAtD1(d2Mesh,d1Mesh)
    }
    insertionClick=()=>{
        const {name} = app.selectMuscle
        let d2Mesh = app.allMeshReference[name+"_zd_d2"]
        let d1Mesh = app.allMeshReference[name+"_zd_d1"]
        app.d2LookAtD1(d2Mesh,d1Mesh)
    }

    enablePanel=(flag)=>{
        app.muscleOption = flag
        this.setState({
            show:flag
        })
        app.DirectionComponent.setState({
            hideLayerChange:flag
        })  
        this.enableMesh(flag)
        app.panel.snapTo(1)
        app.muscleComponent.setPanel(!flag)
        app.muscleComponent.show(!flag)
    }

    render() {
        const {show} = this.state
        const { t} = this.props
        return (
            <>
                {
                    show?<div className="qzd-wrap">
                        <div className="qzd-item-wrap">
                            <Button
                                style={{ backgroundColor:"#ff0000"}}
                                onClick={this.originClick}>
                                {t("inform.origin")}
                            </Button>
                            <p>{app.selectMuscle.dataParent.qidianNote}</p>
                        </div>
                        {/* <Divider></Divider> */}
                        <div className="qzd-item-wrap">
                            <Button
                                style={{ backgroundColor:"#0000ff"}}
                                onClick={this.insertionClick}>
                                {t("inform.insertion")}
                            </Button>
                            <p>{app.selectMuscle.dataParent.zhidianNote}</p>
                        </div>
                        <div className="qzd-close-wrap">
                            <Button onClick={()=>{this.enablePanel(false)}}>
                                <Space>
                                        <LeftOutline/>
                                        {/* app.selectMuscle.dataParent.name */}
                                        <span>{t("common.return")}</span>
                                </Space>
                            </Button>
                        </div>
                    </div>:null
                }
            </>
        )
    }
}

export default withTranslation()(Qzd);
