
const U = window.navigator.userAgent
export const isIpad = U.indexOf('iPad')>0
//是否是pad isPad  1是手机
export const isAndroid = U.indexOf("Android") > -1 || U.indexOf("Adr") > -1

const {origin,port}  = window.location
//是否是开发环境
export const isDev = (port.indexOf('300')!==-1)?true:false
// export const isDev = false

export const errorPic = 'static/img/errorPic.png'

//是否是电脑版 手动设置
export const isPc = true
//是否部署在3dbody上  手动设置
export const is3dbody = true

//用户登录接口   https://musclemee.3dbody.com/    https://www.muscle1.com/

export const net = origin

export const login = net+'/api/passport/account/login'
export const userInfo = net+'/api/u/user/info'

//收藏接口
export const collectType = '1' //收藏类型 1是肌肉动作 2是关节动作 
export const collect = net+'/api/u/action/collect/'
export const collectCancel = net+'/api/u/action/collect/cancel/'
export const collectList = net+'/api/u/action/collect/list/'
//清空收藏 1是清空肌肉动作 2是清关节动作 
export const collectEmpty = net+'/api/u/action/collect/empty'

//搜索接口
export const searchItem = net+'/api/muscle/action/search/item/list'
export const search = net+'/api/muscle/action/search'

//肌肉信息
export const muscleInfo = net+'/api/muscle/info'
export const functionAction = net+'/api/muscle/action/function'
export const stretchingAction = net+'/api/muscle/action/stretching'
export const trainAction = net+'/api/muscle/action/train'

//动作信息
export const actionDetail = net+'/api/muscle/action/detail'

//历史记录
export const actionLogList = net+'/api/u/action/log/list'
export const muscleLogList = net+'/api/u/muscle/log/list'
//肌肉动作清空
export const actionLogEmpty = net+'/api/u/action/log/empty'
//肌肉浏览记录清空
export const muscleLogEmpty = net+'/api/u/muscle/log/empty'

export const testToken = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxODM2OTk1NjI5NSIsImNyZWF0ZWQiOjE2OTU2MTk3MzI5MzYsImV4cCI6MTcwMzM5NTczMn0.UIG2CHHeOaH-g0TDn0u-RJWiavcqU2APPretbEXtHc-7txlhIiYHJLLIo_6O50K12XzTl2xJ75rzvIv5MQmuSQ'
export const muscle1Token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI2NzUxMDY1MzVAcXEuY29tIiwiY3JlYXRlZCI6MTY5OTUwMTU2NjQxMSwiZXhwIjoxNzA3Mjc3NTY2fQ.2dNODiWR9KmvkC93sF1SXS5UGvnfVv3-PSZfjRFxMsY9yHVR7yn1x1Qj7f11Mz7f7ZWbPdY4R1gob6Vd1WuVAw'
export const locationToken = parseURL(window.location.href).params.Authorization

//app搜索过来的动作或者肌肉
export const actionCode = parseURL(window.location.href).params.actionCode
export const muscleId = parseURL(window.location.href).params.muscleId

function parseURL(url) {
    var a = document.createElement('a');
    a.href = url;
    return {
        params: (function () {
            var ret = {},
                seg = a.search.replace(/^\?/, '').split('&'),
                len = seg.length,
                i = 0,
                s;
            for (; i < len; i++) {
                if (!seg[i]) {
                    continue;
                }
                s = seg[i].split('=');
                ret[s[0]] = s[1];
            }
            return ret;
        })()
    }
}

//主站的页面
export const payLink = "https://www.muscle1.com/pricing-motor-anatomy"
export const loginLink = "https://www.muscle1.com/login"



function convertDptoPx(dpi) {
    var px = (1 / window.devicePixelRatio) * dpi; // 根据当前设备的分辨率进行转换
    return Math.round(px);
}
 
var dpValue = 20; // 要转换的dp值
var pxValue = convertDptoPx(dpValue); 
