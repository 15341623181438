import React from "react"
import app from "../../pages/home/src/app.js"
import { updatePlaying } from "../../redux/redux.playing.js"

import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import Muscle from "../muscle/muscle.jsx"
import Progress from "../progress/progress.jsx"

import Qzd from '../muscle/qzd/qzd.js';
import Sj from '../muscle/sj/sj.js';
import Xuegong from '../muscle/xuegong/xuegong.js';
import Lixian from '../muscle/lixian/lixian.js';

import "./inform.less"

@connect(
    // 你要state什么属性放到props里
    (state) => ({
        playing: state.PlayingReducer,
    }),
    // 你要什么方法，放到props里，自动dispatch
    { updatePlaying }
)
class Inform extends React.Component {

    componentDidMount() {
        app.informComponent = this  
        //更新当前播放的动作
        app.updatePlaying = item=> {
            this.props.updatePlaying(item)
        }
    }


    render() {

        return (
            <>
                <Qzd />
                <Sj />
                <Xuegong/>
                <Lixian/>
                <Progress/>   
                <Muscle/>            
            </>

        )
    }
}


export default withTranslation()(Inform)






