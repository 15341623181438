const PLAYING = 'PLAYING'
//初始化的收藏信息
const initPlaying = null
//收藏信息的reducer
export function PlayingReducer(state = initPlaying, action) {
    switch (action.type) {
        case PLAYING:
            return action.payload
        default:
            return state
    }
}

function getPlayingFn(data) {
    return { type: PLAYING, payload: data }
}

export function updatePlaying(data) {
    return dispatch => {
        dispatch(getPlayingFn(data))
    }
}