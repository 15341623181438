//import {  Slider } from 'antd-mobile';\
import React from 'react'

import { Button, CheckList, Popup, Space,ProgressCircle,Slider as Slider2 } from 'antd-mobile'
import { Slider } from 'antd'
import {isPc} from '../../pages/home/config.js'
import app from '../../pages/home/src/app.js'
import { PlayOutline,DownOutline,CloseOutline } from 'antd-mobile-icons'
import {  PauseOutlined} from '@ant-design/icons';
import { withTranslation } from "react-i18next"
import './track.less'


class Track extends React.Component {
    
    state = {
        num:0,
        //展开
        stop:false,
        //速度
        speedArr:[0.5,0.7,1.0,1.5,3.0],
        speed:1,
        speedVisible:false,
        //只有一两帧的动作
        staticPos:false,
        //显示暂停时候需要拖动
        sliderMode:false,
    }

    componentDidMount() {
        app.trackComponent = this
    }

    sliderChange(num){
        //num  0-100
        this.setState({
            num:num
        })
        app.playing.time = (num/100)*app.playing._clip.duration
        if(app.equipPlaying){
            app.equipPlaying.time = (num/100)*app.playing._clip.duration
        }
    }

    playPause =()=>{
        if(app.playing){
            if(app.playing.isRunning()){
                app.playing.paused = true
                this.setState({
                    stop:true
                })
            }else{
                this.setState({
                    stop:false
                })
                app.playing.paused = false 
            }
            if(app.equipPlaying){
                if(app.equipPlaying.isRunning()){
                    app.equipPlaying.paused = true
                }else{
                    app.equipPlaying.paused = false 
                }
            }
        }
    }

    speedClick=(item)=>{
        let itemKey = item
        this.setState({
            speed:itemKey
        })
        if(app.playing){
            app.playing.timeScale = itemKey
        }
        if(app.equipPlaying){
            app.equipPlaying.timeScale = itemKey
        }
    }

    playErrorFn=()=>{
        const { playError } = this.state
        this.setState({
            playError:!playError
        })
    }

    assistClick=(item)=>{
        app.clickOption(item)
    }

    render() {
        const {num,speedArr,speed,stop,speedVisible,handleCollectClick} = this.state
        const {t,data,staticPos} = this.props

        return (

            <div className ='playing-controle-wrap'>
                <div className='playing-controle-circle-wrap'>
                    <Space style={{ '--gap': '5px' }}>
                        <Button className='play-speed-bt' fill='none' onClick={()=>this.setState({speedVisible:true})}>
                            <Space style={{ '--gap': '2px' }}>
                                <span>{ speed&&speed.toFixed(1)+'×'}</span>
                                <DownOutline fontSize={12} />
                            </Space>
                        </Button>

                        <ProgressCircle percent={staticPos?0:num} style={{'--size':'40px','--track-width':'3px'}}>
                            <Button className='play-pause-bt' fill='none' onClick={this.playPause}>
                                {
                                    stop?<PlayOutline/>:<PauseOutlined/>
                                }
                            </Button>
                        </ProgressCircle>
                    </Space>
                </div>


                {!staticPos&&stop?<div className={stop?'progress-bar-wrap stop':'progress-bar-wrap'}>
                    {
                        isPc?<Slider
                                min = {0}
                                tooltip={null}
                                max = {100}
                                style={{ '--fill-color': '#ff8f1f' }}
                                value = {staticPos?100:num}
                                onChange={(num) => {
                                    this.sliderChange(num)
                                }}
                            />:<Slider2
                                min = {0}
                                tooltip={null}
                                max = {100}
                                style={{ '--fill-color': '#ff8f1f' }}
                                value = {staticPos?100:num}
                                onChange={(num) => {
                                    this.sliderChange(num)
                                }}
                        />
                    }
                </div>:null}

                <Popup
                    visible={speedVisible}
                    onMaskClick={() => {
                      this.setState({speedVisible:false})
                    }}
                    maskStyle={{
                        background:'rgba(0,0,0,0.1)',
                        touchAction: 'none',
                    }}
                    // position={isPc?'left':'bottom'}
                    bodyStyle={{
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        minHeight: window.innerWidth>500?'300px':'10vh',
                        maxWidth:'520px',
                    }}
                    destroyOnClose>
                    <CheckList defaultValue={speed ? [speed] : []}
                        onChange={arr => {
                            this.speedClick(arr[0])
                            this.setState({
                                speedVisible:false
                            })
                        }}>
                            {speedArr.map(item => (
                                <CheckList.Item key={item} value={item}>
                                    {item&&item.toFixed(1)+' ×'}
                                </CheckList.Item>
                            ))}
                    </CheckList>
                </Popup>
                
            </div>
        )
    }
}

export default withTranslation()(Track)








