import React from 'react'
import app from '../../pages/home/src/app.js'
import { isDev,isAndroid,testToken,is3dbody,muscle1Token,locationToken,isPc,loginLink} from '../../pages/home/config.js'
import axios from 'axios'
import { Dialog} from 'antd-mobile'
import './iframe.less'

if(isDev){
    app.token = isDev?is3dbody?testToken:muscle1Token:null
}else{
    if(is3dbody){
        app.token = locationToken?locationToken:null 
    }else{

    }
}
axios.defaults.timeout=5000
axios.interceptors.request.use(
    config => {
        //实时更新token
        if(!!app.token){
            config.headers.Authorization = 'Bearer '+app.token
        }else{
            console.log('无app.token')
        }
       config.headers.language = app.lang
       return config
    },
    err => {
      return Promise.reject(err);
    }
)
class Iframe extends React.Component {
    state = {
        isModalOpen:false
    }
    componentDidMount() {
        const _this = this
        app.iframeComponent = this 
        
        //部署在国外的时候
        if(is3dbody===false){
            //电脑版直接从localstorege获取
            if(isPc){
                this.getTokenFromWeb()
            //手机版与app交互获取
            }else{
                this.getTokenFromApp()
                //供app调用给app.token赋值
                app.passTokenToJs=(token)=>{
                    if(token&&token.length>20){
                        _this.setToken(token)
                    }
                }
                app.passLanguageTypeToJs=(lang)=>{
                    console.log('passLanguageTypeToJs lang:'+lang)
                    if(lang){
                        app.lang=lang
                        localStorage.setItem('lang',lang)
                        app.changeLang()
                        //第一次app传给语言值  载入模型
                        if(app.getLangFormApp === false){
                            app.indexComponent.initLoad()
                            app.getLangFormApp = true
                        }
                    }
                }
            }
        }
        

    }

    //执行后获取app调用网页app.passLanguageTypeToJs方法
    getTokenFromApp=()=>{
        try {
            if (isAndroid) {
                Android.getToken(1)
                Android.getlanguageType(1)
            } else {
                window.webkit.messageHandlers.getToken.postMessage(1)
                window.webkit.messageHandlers.getlanguageType.postMessage(1)
            }
        } catch (error) {
            console.log(error)
        }
    }

    getTokenFromWeb=()=>{
        let token = localStorage.getItem('token')
        if(token&&token.length>20){
            this.setToken(token)
        }
    }


    //给iframe更新token
    setToken=(token)=>{
        console.log('setToken:'+token)
        app.token = token
        localStorage.setItem('token',token)
    }
    removeToken=()=>{
        app.token=null
        localStorage.removeItem('token')
    }

    showLogin=()=>{
        const _this = this 
        Dialog.confirm({
            title: 'Login',
            bodyStyle:{'zIndex':'999'},
            cancelText:'No',
            confirmText:'Yes',
            content: 'Go to Login?',
            onConfirm:()=>{
                Dialog.clear()
                _this.goLoginPage()
            },
            onCancel:()=>{
                console.log('onCancel')
            }
        })
    }
    goLoginPage=()=>{
        if(isPc){
            window.open(loginLink)
        }else{
            try {
                if (isAndroid) {
                    Android.toLogin(1)
                } else {
                    window.webkit.messageHandlers.toLogin.postMessage(1)
                }
            } catch (error) {
                console.log(error)
            }
        }

        this.showAfterLoginModal()
    }

    showAfterLoginModal = () => {
        const _this = this
        Dialog.confirm({
            title: 'Have you logged in？',
            bodyStyle:{'zIndex':'999'},
            cancelText:'No',
            confirmText:'Yes',
            content:<>
                    <p>If your login.</p>
                    <p>Please Confirm!</p></>,
            onConfirm:()=>{
                if(isPc){
                    _this.getTokenFromWeb()
                }else{
                    _this.getTokenFromApp()
                }
            },
            onCancel:()=>{
                console.log('onCancel')
            }
        })
    }

    showReloadModal = () => {
        Dialog.confirm({
            title: 'Reload',
            bodyStyle:{'zIndex':'999'},
            cancelText:'No',
            confirmText:'Yes',
            content:<>
                    <p>If your plans has changed.</p>
                    <p>Please reload the page</p></>,
            onConfirm:()=>{window.location.reload()},
            onCancel:()=>{
                console.log('onCancel')
            }
        })
    }

    render() {

        return (
            <div className = 'iframe-wrap'></div>
        )
    }
}

export default Iframe

