import React from "react"
import { Spin,Typography,Button} from "antd"
import axios from "axios"
import app from "../../pages/home/src/app.js"
import { withTranslation } from "react-i18next"
import Sheet from 'react-modal-sheet';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {  Divider,TabBar,Space} from 'antd-mobile'

import {
    InformationCircleOutline,
} from 'antd-mobile-icons'
import {
    functionAction,
    stretchingAction,
    trainAction,
    isPc
} from "../../pages/home/config.js"
import ActionWrap from "./actionwrap.js"
import InstrumentActionWrap from './instrumenactionwrap.js'

// import qizhidian from "./img/qizhidian.png"
// import shenjingzhipei from "./img/shenjing.png"
// import xuegongImg from "./img/xueye.png"
// import lixianImg from "./img/jishu-line.png"

import qizhidian_A from "./img/qizhidian-light.png"
import shenjingzhipei_A from "./img/shenjing-light.png"
import xuegongImg_A from "./img/xueye-light.png"
import lixianImg_A from "./img/jishu-line-light.png"


import functionImg  from './img/function-B.png'
import trainImg  from './img/train-B.png'
import stretchingImg  from './img/stretching-B.png'
import functionImg_g  from './img/function-g.png'
import trainImg_g  from './img/train-g.png'
import stretchingImg_g  from './img/stretching-g.png'

import "./muscle.less"

const { Text,Title} = Typography

let clickPath = ''
app.pathname = 'inform'
class Muscle extends React.Component {
    state = {
        show: false,
        //右侧功能类型是否展开
        showType: true,
        //动作
        axiosAllActionList: null,
        activeList: null,
        //器械
        //器械是否展开
        showInstrument: false,
        instrumentIndex: "0",
        instrumentTypeList: null,
        //选中模型的名字
        selectName: "",
        //整个right panel下载
        allLoading: false,
        //整个内容
        data: null,
        //当前点击模型的信息
        objDataParent: null,
        //动作内容加载
        loading: false,
        //当前选中的功能类型下标
        activeType: "inform",

        showQizhi: false,
        showShenjing: false,
        //显示肌肉相关信息
        showDes: true,
        //显示肌肉信息的高度
        isOpen:false,
        tabs:[],
        showProgress:false,
        pathname:'inform',
        panelHeight:0,

    }

    componentDidMount() {
        app.muscleComponent = this
    }

    typeClick = (action) => {
        const id = app.selectMuscle.dataParent.uuid
        if (id === undefined) return
        this.setState({
            loading: true,
            activeType: action,
            showInstrument: false,
        })
        app.fnType = action
        if(action === 'inform'){
            return
        }
        let location
        if (action == '1') {
            location = functionAction
        } else if (action == '2') {
            location = trainAction;
        } else {
            location = stretchingAction;
        }
        app.pending = true;
        axios
            .get(location, { params: { muscleId: id } })
            .then((response) => {
                if (response.data.code === "OK") {
                    const data = response.data.data
                    let instrumentTypeList = []
                    this.setState({
                        axiosAllActionList: data,
                        loading: false,
                    })
                    if (data && data.length) {
                        data.map((item) => {
                            if (
                                item.instrumentType &&
                                instrumentTypeList.indexOf(item.instrumentType) === -1
                            ) {
                                instrumentTypeList.push(item.instrumentType)
                            }
                        })
                        //有器械
                        if (instrumentTypeList.length) {
                            let activeKey = instrumentTypeList[0]
                            let activeList = data.filter((item) => {
                                return item.instrumentType === activeKey
                            })
                            this.setState({
                                instrumentTypeList: instrumentTypeList,
                                showInstrument: true,
                                activeList: activeList,
                            })
                            //无器械  是功能动作或者拉伸动作
                        } else {
                            this.setState({
                                instrumentTypeList: [],
                                showInstrument: false,
                                activeList: data,
                            })
                        }
                    }
                } else {
                    this.setState({
                        loading: false,
                    })
                }
                app.pending = false
            })
            .catch((error) => {
                app.pending = false
                this.setState({
                    loading: false,
                });
            });
    }
    
    //更新换一下肌肉信息都有哪些类型
    updateData=(obj)=>{
        console.log(obj)
        this.setState(obj)
        if(obj.data){
            this.setData(obj.data)
        }
    }

    showQizhidian=()=>{
        app.qzdComponent.enablePanel(true)
    }
    showSj=()=>{
        app.sjComponent.enablePanel(true)
    }
    showXuegong=()=>{
        app.xuegongComponent.enablePanel(true)
    }

    showLixian=()=>{
        app.lixianComponent.enablePanel(true)
    }

        
    setData=(data)=>{
        const {t} = this.props
        let tabs = [{
            key: 'inform',
            title: t("inform.inform"),
            icon: <InformationCircleOutline fontSize={28}/>
          }]
        if(data.function){
            tabs.push({
                key: '1',
                title: t("inform.functionAnalysis"),
                icon:(active) => <img  style={{'height':"28px","width":"auto"}} src={active?functionImg:functionImg_g} /> 
            })
        }
        if(data.train){
            tabs.push({
                key: '2',
                title: t("inform.strength"),
                icon: (active) =><img  style={{'height':"28px","width":"auto"}} src={active?trainImg:trainImg_g} />
              })
        }
        if(data.stretching){
            tabs.push({
                key: '3',
                title:t("inform.stretching"),
                icon: (active) =><img  style={{'height':"28px","width":"auto"}} src={active?stretchingImg:stretchingImg_g} />,
            })
        }    
        this.setState({
            tabs:tabs
        })
    }

    setPanel=(flag)=>{
        this.setState({
            isOpen:flag
        })
    }

    show=(flag)=>{
        this.setState({
            show:flag
        })
    }


    setRouteActive = (value) => {
        const {pathname} = this.state
        app.prePathName = pathname
        app.pathname = value
        this.setState({
            pathname:value
        })
        if(app.muscleComponent){
            app.muscleComponent.typeClick(value)
        }
    }
    preMuscleTap=()=>{
        //返回上一个肌肉是还原当前肌肉信息的操作
        app.sliderComponent.resetSlider()
        if(app.preMuscleArr.length>1){
            app.clickOption(app.preMuscleArr[1],null,true)
            app.lookAtSkinMesh(app.preMuscleArr[1])
            app.preMuscleArr.shift()
        }
    }

    render() {
        const {
            showInstrument,
            instrumentTypeList,
            allLoading,
            loading,
            activeType,
            axiosAllActionList,pathname,tabs,show,isOpen
        } = this.state
        const { t } = this.props
        let objDataParent
        let preMuscleName 
        if(app.selectMuscle){
            objDataParent = app.selectMuscle.dataParent
            preMuscleName = app.preMuscleArr.length>1?app.preMuscleArr[1].dataParent.name:null
        }

        return (
            <>
                {
                    show&&preMuscleName?<div onClick={this.preMuscleTap} className="tap-change">
                        <Button size="small" shape="rounded">
                            <Space  style={{'--gap':'2px'}}><LeftOutlined /> <span>{preMuscleName}</span></Space>
                        </Button>
                    </div>:null
                }
                <Sheet
                    // rootId="root"
                    ref={ref=>{app.panel=ref}}
                    style={{'zIndex':'7','maxWidth':'500px'}}
                    isOpen={isOpen}
                    onClose={() => {
                        //只有有当前点击的肌肉信息或者正在播放就不隐藏sheet
                        if(show){app.panel.snapTo(1)}else{this.setState({isOpen:false})}
                    }}
                    disableDrag={isPc?true:false}
                    snapPoints={[isPc?window.innerHeight:window.innerHeight-150,isPc?window.innerHeight:180]}
                    initialSnap={1}
                    onSnap={snapIndex =>{console.log('> Current snap point index:', snapIndex)}}>
                    <Sheet.Container>
                        <Sheet.Header onClick={()=>{app.panel.snapTo(app.panel.y.current===0?1:0)}} />
                        <Sheet.Content style={{position:'relative' }}>
                        <Sheet.Scroller draggableAt='top'>
                            <div className="muscle-sheet-wrap">
                                {
                                    show? <>
                
                                        <div className="select-inform-wrap">
                                            <div className="select-name-wrap">
                                                <Title className="select-name">{objDataParent?objDataParent.name:null}</Title>
                                            </div>
                                        </div>
                                        <div className={activeType==='inform'?"inform-bt-wrap":'inform-bt-wrap hide'}>
                                            {objDataParent ? (
                                                <div id='des-contain' className= "des-contain showdes">
                                                    {objDataParent.qidianNote ? (<>
                                                        <Divider style={{'margin':'5px 0'}}/>
                                                        <div className="des-item">
                                                            <div className="fn-bt-wrap">
                                                                <Button onClick={this.showQizhidian}>
                                                                    <Space style={{'--gap':'0px'}} wrap justify='center' align='center' direction='vertical'>
                                                                        <img src={qizhidian_A} />
                                                                        <p>{t("inform.originAndInsertion")}</p>
                                                                    </Space>
                                                                </Button>
                                                            </div>
                                                            <div className="select-note-wrap">
                                                                <p><Text>{objDataParent.qidianNote}</Text></p>
                                                                <p><Text>{objDataParent.zhidianNote}</Text></p>
                                                            </div>
                                                        </div></>
                                                    ) : null}

                                                     <>
                                                        <Divider style={{'margin':'5px 0'}}/>
                                                        {
                                                            objDataParent.JLXZY?<div className="des-item">
                                                                <div  className="fn-bt-wrap">
                                                                    <Button
                                                                        onClick={this.showLixian}>
                                                                        <Space style={{'--gap':'0px'}} wrap justify='center' align='center' direction='vertical'>
                                                                            <img src={lixianImg_A} />
                                                                            <p>{t("inform.lixian")}</p>
                                                                        </Space>
                                                                        
                                                                    </Button>
                                                                </div>
                                                                <div className="select-note-wrap">
                                                                    <p><Text><span dangerouslySetInnerHTML={{'__html':objDataParent.JLXZY}}></span></Text></p>
                                                                </div>
                                                            </div>:null
                                                        }
                                                    </>

                                                    {objDataParent.shenjingNote ? (<>
                                                        <Divider style={{'margin':'5px 0'}}/>
                                                        <div className="des-item">
                                                            <div  className="fn-bt-wrap">
                                                                <Button onClick={this.showSj}>
                                                                    <Space style={{'--gap':'0px'}} wrap justify='center' align='center' direction='vertical'>
                                                                        <img src={shenjingzhipei_A} />
                                                                        <p>{t("inform.innervation")}</p>
                                                                    </Space>
                                                                </Button>
                                                            </div>
                                                            <div className="select-note-wrap">
                                                                <p><Text>{objDataParent.shenjingNote}</Text></p>
                                                            </div>
                                                        </div></>
                                                    ) : null}
                                                


                                                    
                                                    {objDataParent.Bloodsupply?(<>
                                                        <Divider style={{'margin':'5px 0'}}/>
                                                        <div className="des-item">
                                                            <div  className="fn-bt-wrap">
                                                                <Button
                                                                    onClick={this.showXuegong}>
                                                                        <Space style={{'--gap':'0px'}} wrap justify='center' align='center' direction='vertical'>
                                                                            <img src={xuegongImg_A} />
                                                                            <p>{t("inform.ploodsupply")}</p>
                                                                        </Space>
                                                                </Button>
                                                            </div>
                                                            <div className="select-note-wrap">
                                                                {/* {
                                                                    objDataParent.shenjingNote?<p><Text>{objDataParent.shenjingNote}</Text></p>:null  
                                                                } */}
                                                            </div>
                                                        </div></>
                                                    ) : null}

                                                
                                                </div>
                                            ) : null}
                                        </div>
                                        <div id = 'muscle-action-wrap' className={activeType==='inform'?'hide':"muscle-action-wrap"}>
                                            {axiosAllActionList&&axiosAllActionList.length && !showInstrument?
                                                <ActionWrap data ={axiosAllActionList} loading={loading}/>:null
                                            }
                                            {axiosAllActionList&&axiosAllActionList.length && showInstrument?<InstrumentActionWrap  
                                                instrumentTypeList = {instrumentTypeList}
                                                data ={axiosAllActionList} loading={loading}/>:null
                                            }
                                            {loading ? (
                                                <div className="muscle-action-wrap-loading">
                                                    <Spin />
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className={ allLoading
                                                ? "select-muscle-wrap-loading"
                                                : "hide"}>
                                            <Spin />
                                        </div>
                                    </>:null
                                }
                            </div>
                        </Sheet.Scroller>
                        </Sheet.Content>
                    </Sheet.Container>
                    {/* <Sheet.Backdrop /> */}
                </Sheet>
                {show?<div onClick={()=>{
                        if(clickPath&&clickPath===app.pathname&& app.panel.y.current===0){
                            app.panel.snapTo(1)
                            app.muscleComponent.setPanel(true)
                            clickPath = ''
                        }else{
                            app.panel.snapTo(0)
                            app.muscleComponent.setPanel(true)
                            clickPath = app.pathname
                        }}} className={isPc?"tab-bar-wrap isPc":"tab-bar-wrap"}>
                        <div className="tab-bar-wrap-innner">
                            <TabBar activeKey={pathname} 
                                onChange={(value,index) => {
                                    this.setRouteActive(value,index)
                                }} >
                                {tabs.map(item => (
                                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                                ))}
                            </TabBar>
                        </div>
                    </div>:null
                }
            </>
        )
    }
}

export default withTranslation()(Muscle);
