const Collect_DATA = 'Collect_DATA'
//初始化的收藏信息
const initCollectData = []
//收藏信息的reducer
export function CollectDataReducer(state = initCollectData, action) {
    switch (action.type) {
        case Collect_DATA:
            return action.payload
        default:
            return state
    }
}

function getCollectDataFn(data) {
    return { type: Collect_DATA, payload: data }
}

export function updateCollectInform(data) {
    return dispatch => {
        dispatch(getCollectDataFn(data))
    }
}