import React from 'react'
import axios from 'axios'
import { Pagination,Spin,Empty,Button } from 'antd';
import { Popup, Toast,Space,Dialog,ErrorBlock} from 'antd-mobile'
import app from '../../pages/home/src/app.js'
import { withTranslation } from "react-i18next"
import { actionLogList, actionLogEmpty,isPc} from "../../pages/home/config.js";
import ActionItem from '../actionItem/actionItem.js';

import './history.less'

class History extends React.Component {
    state = {
        hasLogin: true,
        showLogin:false,
        showPanel:false,
        historyList:[],
        page:1,
        pageSize:isPc?12:9,
        axiosAllData:null,
        empty:false
    }
    componentDidMount() { 
        app.historyComponent = this
    }
    updateActionHistory=()=>{
        const {page,pageSize} = this.state
        const _this = this
        if(app.token === undefined){ return }
        axios.get(actionLogList+`?page=${ page }&size=${pageSize}`).then((response) => {
            console.log(response)
            if(response.data.code === "OK"){
                if(response.data.data.totalRecord>0){
                    _this.setState({
                        axiosAllData:response.data.data
                    })
                }else{
                    _this.setState({
                        axiosAllData:null,
                        empty:true
                    })
                }
            }else{
                Toast.show({ content:response.data.code})
                _this.setState({
                    axiosAllData:null,
                    empty:true
                })
                console.log(response.data.code)
            }
        }).catch(error=>{
            console.log(error)
            _this.setState({
                axiosAllData:null,
                empty:true
            })
        })
    }

    showPanel =()=>{
        this.setState({
            showPanel:true
        })
    }
    onClose = () => {
        this.setState({
            showPanel:false
        })
    }

    paginationChange=(page,pageSize)=>{
        const {axiosAllData}  = this.state
        axiosAllData.list = []
        this.setState({
            page:page,
            pageSize:pageSize,
            axiosAllData:axiosAllData
        })
        setTimeout(() => {
            this.updateActionHistory()
        }, 10)
    }
    emptyHistory=()=>{
        const {t}=this.props
        const _this = this
        Dialog.confirm({
            title: t('history.clearHistory'),
            bodyStyle:{'zIndex':'999'},
            cancelText:'No',
            confirmText:'Yes',
            content: t('history.clearAllRecords'),
            onConfirm:()=>{
                Dialog.clear()
                _this.fetchEmpty()
            },
            onCancel:()=>{
                console.log('onCancel')
            }
        })
    }
    fetchEmpty=()=>{
        const {t}=this.props
        axios.post(actionLogEmpty).then((response) => {
            console.log(response)
            if(response.data.code === "OK"){
                Toast.show({ content:t('history.clearSuccess')})
            }else{
                // Toast.show({ content:response.data.code})
                Toast.show({ content:t('history.clearFailed')})
            }
            this.setState({showPanel:false})
        }).catch(error=>{
            console.log(error)
            Toast.show({ content:t('history.clearFailed')})
        })
    }

    render() {
        const {t} = this.props
        const { showPanel,axiosAllData,pageSize,empty} = this.state
        return (
                <Popup
                    // title={t('collect.myCollect')}
                    visible={showPanel}
                    // showCloseButton
                    onMaskClick={() => {
                        this.setState({showPanel:false})
                    }}
                    // showCloseButton= {true}
                    maskStyle={{
                        background:'rgba(0,0,0,0)',
                        touchAction: 'none',
                    }}
                    // position={isPc?'left':'bottom'}
                    bodyStyle={{
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        minHeight: isPc?'100vh':'85vh',
                        maxWidth:'500px',
                    }}>
                    <div className='history-contain-wrap'>
                        <div className='history-top-bt-wrap'>
                            <span className='collect-title'>
                                {t('history.title')}
                            </span>

                            <Space style={{'float':'right','--gap':'12px'}}>
                                {
                                    empty===false?<Button
                                        onClick={this.emptyHistory}
                                        fill='none'>
                                        {t('common.clear')}
                                    </Button>:null
                                }

                                <Button color='primary'
                                    onClick={()=>{this.setState({showPanel:false})}}
                                    fill='none'>
                                    {t('common.cancel')}
                                </Button>
                            </Space>

                       </div>    
                        <div className='historyList-wrap'>
                            <div className='filter-contain-wrap'>
                                {
                                    axiosAllData&&axiosAllData.list.length?axiosAllData.list.map((item,index)=>{
                                        let percent
                                        if(window.innerWidth>500){
                                            percent = 25
                                        }else{
                                            let num = parseInt(window.innerWidth/120)
                                            percent= parseFloat(100/num).toFixed(2)
                                        }
                                        return <ActionItem
                                            widthStr={`calc(${percent}% - 10px)`}
                                            data={item}
                                            cb={this.onClose}
                                            key={index}/>
                                    }):<div className="filter-contain-wrap-loading">
                                        {empty?<ErrorBlock status='empty' description={''} title={t('common.noRecord')}>
                                            <Button color='primary' onClick={()=>{this.setState({showPanel:false})}}>{t('history.toExplore')}</Button>
                                        </ErrorBlock>:<Spin/>}</div>
                                }
                            </div>
                            {
                                axiosAllData&&axiosAllData.totalRecord>pageSize?
                                    <div className='filter-pagination-wrap'><Pagination 
                                        pageSize = {axiosAllData.pageSize}
                                        current = {axiosAllData.pageIndex}
                                        onChange={this.paginationChange} 
                                        total={axiosAllData.totalRecord} />
                                    </div>
                                :null
                            }
                        </div>
                    </div>
                </Popup>
            )
    }
}



export default withTranslation()(History)



