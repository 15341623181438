import React from 'react'
import app from '../../pages/home/src/app.js'
import { withTranslation } from "react-i18next"
import './interactHelp.less'

class InteractHelp extends React.Component {
    state = {
        //假设他已经点击过 
        hasInteract: false,
    }

    componentDidMount() {
        app.interactHelpComponent = this
    }
    
    //初始化是否有点击过
    initHasInteract=()=>{
        this.setState({
            hasInteract: false,
        })     
    }

    closeHelp = () => {
        if(this.state.hasInteract){
            return
        }
        this.setState({
            hasInteract:true
        })
        localStorage.setItem('hasInteract',true)
    }

    render() {
        const {hasInteract} = this.state
        const {t} = this.props
        return (
            <>
                {
                    !hasInteract?<div className = 'interact-help-wrap'>
                        <div className = 'interact-help'>
                            <span className='interact-img'></span>
                            <span className='help-title'>{t('interactHelp.title')}</span>
                        </div>
                    </div>:null
                }
            </>
        )
    }
}

export default withTranslation()(InteractHelp)