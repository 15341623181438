import React from "react"
import { Button,Space} from "antd-mobile"
import app from "../../../pages/home/src/app.js"
import { withTranslation } from "react-i18next"
import { LeftOutline } from 'antd-mobile-icons'
import "./lixian.less"

class Lixian extends React.Component {
    state = {
        //显示肌肉信息的高度
        show:false,
        showMuscle:false,
        list:[]
    }

    componentDidMount() {
        app.lixianComponent = this
    }

    //显示起止点
    enableMesh = (flag) => {
        if (!app.selectMuscle) return
        // app.progressComponent.closePanel()
        const handleMesh =()=>{
            // let Bloodsupply = app.selectMuscle.dataParent.Bloodsupply
            // if (Bloodsupply === undefined) return
            app.hideOriginsAndinsertionAndNerves()
            if (flag) {
                app.handleResetPrePlaying()
                app.resetAllTempOption()
                if(app.progressComponent){
                    app.progressComponent.setState({
                        show: false,
                    })
                }
                if (app.fixedObj) {
                    app.fixedObj.visible = false
                }
                app.allMuscle.map((item) => {
                    item.visible = false
                    item.material.opacity = 1
                    item.renderOrder = 6
                })
                app.selectMuscle.visible = false
                app.selectMuscle.material.opacity = 0.2
                app.selectMuscle.renderOrder = 8
           
                let obj = app.allMeshReference[app.selectMuscle.name+'_LX']
                if (obj) {
                    console.log(obj)
                    obj.visible = true
                    obj.material.emissive = app.whiteColor
                    obj.material.emissiveIntensity = .1
                    app.visibleOriginsAndinsertionAndNerves.push(obj)
                    app.lookAtSelectObj([obj])
                }
            } else {
                app.sliderComponent.resetSlider()
                app.centerScene()
            }
        }
        if(app.selectMuscle.lixian){
            handleMesh()
        }else{
            app.loadSJQZD(app.selectMuscle,'lixian',()=>{
                handleMesh()
            }, ()=>{
                console.log(app.selectMuscle,'神经加载失败哎')
            })
        }
    }


    itemClick=(obj)=>{
        app.lookAtSelectObj([obj])
        app.clickOption(obj)
    }

    enablePanel=(flag)=>{
        app.muscleOption = flag
        this.setState({
            show:flag
        })
        app.DirectionComponent.setState({
            hideLayerChange:flag
        })  
        this.enableMesh(flag)
        app.panel.snapTo(1)
        app.muscleComponent.setPanel(!flag)
        app.muscleComponent.show(!flag)
    }

    muscleClick=()=>{
        const {showMuscle} = this.state
        this.setState({
            showMuscle:!showMuscle
        })
        app.selectMuscle.visible = !showMuscle
    }

    render() {
        const {show,list,showMuscle} = this.state
        const { t} = this.props
        return (
            <>
                {
                    show?<div className="lixian-wrap">
                        <div className="lixian-item-wrap">
                            {/* {
                                list.map((item,index)=>{
                                    return <Button fill='outline' key={index}
                                    onClick={this.itemClick.bind(this,item)}>
                                        {item.dataParent.name}
                                    </Button>
                                })
                            } */}
                            <Button fill={showMuscle?'solid':'none'} 
                                onClick={this.muscleClick}>
                                {app.selectMuscle.dataParent.name}
                            </Button>
                        </div>

                        <div className="lixian-close-wrap">
                            <Button onClick={()=>{this.enablePanel(false)}}>
                                <Space>
                                    <LeftOutline/>
                                    <span>{t("common.return")}</span>
                                </Space>
                            </Button>
                        </div>
                    </div>:null
                }
            </>
        )
    }
}

export default withTranslation()(Lixian)
