import { Button, Popup, Divider, Slider } from 'antd-mobile'
import React from 'react';

import { UndoOutline,CloseOutline,DownOutline,UpOutline} from 'antd-mobile-icons'
import { withTranslation } from "react-i18next"
import app from '../../pages/home/src/app.js'
import canvasDrawer from './canvasDraw.js'
import './draw.less'

const fnTypeArr = ['brush', 'eraser', 'line', 'rectangle', 'circle', 'arrows']
const colorArr = ['#000000', '#ff0000', '#00ff00', '#0000ff', '#ffff00', '#00ffff', '#ff00ff', '#ffffff']

class Draw extends React.Component {

    state = {
        //画笔相关
        colorList: colorArr,
        colorIndex: 0,
        color: colorArr[1],
        showOption: false,
        showCanvas: false,

        lineWidth:6,
        opacity:80,

        canClear: false,
        fnType: fnTypeArr[0],
        fnTypeList: fnTypeArr,
        lineWidthPopup: false
    }

    componentDidMount() {
        app.drawerComponent = this
        this.canvasDrawer = canvasDrawer
        canvasDrawer.init(this.state.CanvasDom, this)
        // 
    }

    closeDraw =()=>{
        console.log(this.props)
        app.DirectionComponent.toggleDraw()
        canvasDrawer.resetCanvas()
    }

    colorClick(item, index) {
        this.setState({
            color: item,
            colorIndex: index
        })
    }
    log = (name) => {
        return (value) => {
            console.log(`${name}: ${value}`);
        };
    }

    lineWidthChange = (value) => {
        this.setState({
            lineWidth: value
        })
    }
    opacityChange = (value) => {
        this.setState({
            opacity: value / 10
        })
    }

    toggleOptionBar = () => {
        this.setState({
            showOption: !this.state.showOption
        })
    }

    colorInputChange = (e, value) => {
        console.log(e.currentTarget.value)
        this.setState({
            color: e.currentTarget.value,
            colorIndex: null
        })
    }

    fnTypeClick = (type) => {
        this.setState({
            fnType: type
        })
        if(type==='eraser'){
            

        }
    }

    lineWidthClick = (item) => {
        console.log(item)
        this.setState({
            lineWidth: item.key
        })
    }

    opacityClick = (item) => {
        console.log(item)
        this.setState({
            opacity: item.key
        })
    }

    getImgFromScreen = () => {
        app.reactDomComponent.getImgFromScreen()
    }

    render() {

        const { showOption, showCanvas, fnType, fnTypeList, canClear, lineWidthPopup,
            lineWidthList, color, lineWidth, opacityList, opacity } = this.state
            const {t} = this.props

        return (
            <div id ='draw-wrap' className='draw-wrap'>

                <canvas className={showCanvas ? 'canvasDraw' : 'canvasDraw hide'}
                    ref={el => this.state.CanvasDom = el}></canvas>

                <div className={showOption ? 'draw-option-wrap active' : 'draw-option-wrap'}>

                    <div className={showCanvas ?'draw-tool-list':'hide'}>

                        <div onClick={canvasDrawer.returnPre}
                            className={canClear ? 'draw-tool-list-item draw-cencer active' : 'draw-tool-list-item draw-cencer'} >
                            <Button disabled={canClear ? false : true} type='text' fill='none'>
                                {t("draw.undo")}
                            </Button>
                        </div>

                        <div
                            onClick={canvasDrawer.resetCanvas}
                            className='draw-tool-list-item draw-clear '>
                            <Button disabled={canClear ? false : true} type='text' fill='none'>
                               {t("draw.clear")}
                            </Button>
                        </div>

                        <div className='draw-tool-list-item huabi-sign-wrap'>
                     
                            <div className='radius-sign'>
                                <div style={{
                                    'opacity': opacity + '%', "backgroundColor": color,
                                    'width': lineWidth * 1.2 + 'px', 'height': +lineWidth * 1.2 + 'px'
                                }} className='opacity-wrap'></div>
                            </div>
                        </div>

                        <ul className='draw-tool-list-right'>
                            {/* <li
                                onClick={app.cutScreen}
                                className= 'catImg'>
                            </li> */}
                            <li
                                onClick={this.toggleOptionBar}
                                className= 'toggle-optionBar'>
                                {
                                    showOption ? <DownOutline /> : <UpOutline />
                                }
                            </li>
                            <li
                                onClick={this.closeDraw}
                                className= 'close-draw'>
                                <CloseOutline />
                            </li>
                        </ul>

                    </div>

                    <div className='brush-setting-wrap'>
                        <div className='brush-setting-title'>{t("draw.size")}</div>
                        <div className='brush-setting-slider-wrap'>
                            <Slider min={1}  value={lineWidth} max={32} popover 
                                onChange={value=>{
                                    this.setState({
                                        lineWidth:value
                                    })
                                }}/></div>
                    </div>
                    <div className='brush-setting-wrap'>
                        <div className='brush-setting-title'>{t("draw.opacity")}</div>
                        <div className='brush-setting-slider-wrap'>
                            <Slider min={20}  value={opacity} max={100} popover 
                                onChange={value=>{
                                    this.setState({
                                        opacity:value
                                    })
                                }}/></div>
                    </div>

                    <div className='brush-setting-wrap'>
                        <div className='brush-setting-title'>{t("draw.tool")}</div>
                        <div className='brush-setting-slider-wrap'>
                            <div className='draw-type-wrap'>
                                {
                                    fnTypeList.map((item, index) => {
                                        return <div key={index}
                                            onClick={this.fnTypeClick.bind(this, item)}
                                            className={fnType === item ? `draw-type-item ${item} active` : `draw-type-item ${item}`}></div>
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="draw-color-wrap">
                        <ul className='draw-color-list'>
                            {
                                this.state.colorList ? this.state.colorList.map((item, index) => (
                                    <li key={index} className={color === item ? 'active' : null}
                                        onClick={this.colorClick.bind(this, item, index)}><p style={{ 'backgroundColor': item }}></p></li>
                                )) : null
                            }
                            <input onChange={this.colorInputChange.bind(this)} className='colorInput' type="color" />
                        </ul>
                    </div>

                </div>

            </div>

        )
    }
}

export default withTranslation()(Draw)