import React from 'react'
import app from '../../pages/home/src/app.js'
import './mousemove.less'


class Menu extends React.Component {

    state = {
        mousemoveName:null,
        hasData:false,
        pos:{
            x:300,
            y:400,
        }
    }

    componentDidMount() {
        app.mousemoveComponent = this
        this.state.MousemoveDom.addEventListener('mouseenter',()=>{
            this.setState({
                mousemoveName: null
            })
        })
    }

    setMouseMoveName(name) {
        this.setState({
            mousemoveName: name
        })
    }

    render() {
        const {pos,mousemoveName,hasData} = this.state

        return ( 
            <div ref = { el => this.state.MousemoveDom = el } 
            style = {{left:pos.x+'px',top:pos.y+'px'}} 
            className= {hasData?'mousemove-dragger hasData':'mousemove-dragger'}>
                <div className= { mousemoveName?'move-name':'hide'} >{mousemoveName}</div>
            </div>   
        )
    }
}

export default Menu