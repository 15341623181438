import {
    OrbitControls
} from './dev/jsm/controls/OrbitControls.js'
import {
    GLTFExporter
} from './dev/js/exporters/GLTFExporter.js'

import {Toast} from "antd-mobile"
// import { CSS2DRenderer, CSS2DObject } from './dev/jsm/renderers/CSS2DRenderer2.js';
import {
    GPUPicker
} from './js/three_gpu_picking.js'
import Stats from './dev/jsm/libs/stats.module.js';
import TWEEN from './js/tween.js'

import axios from 'axios'
import {muscleInfo,actionDetail,isPc, isDev} from '../config.js'
// console.log(JSON.stringify(allObj_cn[0].arr))

const app = (function () {

    let camera,
        scene,
        sceneEquip,
        renderer,
        clock = new THREE.Clock(),
        clock2 = new THREE.Clock(),
        labelRenderer,
        picker,
        stats

    function APPFN(dom) {
        this.TWEEN = TWEEN
        this.selectObj = []
        this.objects = []
        this.jingxiang = false
        this.clickObj = null
        //当前点击动作类型 1是功能动作 2是力量训练  3是拉伸动作
        this.fnType = 'inform'

        this.screen = {
            w: window.innerWidth,
            h: window.innerHeight,
        }
        //操作过程中需要暂时显示的模型  例如
        this.tempMeshList = []
        this.muscleLayerArr = [
            [],[],[],[],[],[],[],[]
        ]
        //肌肉当前是第几层
        this.muscleLayerIndex = 6

        this.dyeColor = new THREE.Color(0xcd7f32)

        // this.selectColor = new THREE.Color(0xffdf08)
        // this.selectColor = new THREE.Color(0x3f8a3f)
        this.selectColor = new THREE.Color(0x93ca93)
        this.whiteColor = new THREE.Color(0xffffff)
        this.resetColor = new THREE.Color(0x000000)
        // this.mouseoverColor = new THREE.Color(0x8dcc8d)
        this.mouseoverColor = new THREE.Color(0xc0edc0)

        this.neversColor = new THREE.Color(0x00ce00)

        this.mouse = new THREE.Vector2()
        //创建group树的时候做的映射表避免多次使用app.scene.getObjectByName影响性能 
        this.groupLinkToMesh = {}
        this.groupLinkToId = {}
        //双击计时
        this.doubleClick = false
        //双击计时定时器 
        this.doubleClickTimer = null

        this.allAction = {}
        this.selectMuscle = null

        this.tempMeshList = []
        //播放待机动画
        this.playIdle = false
        this.idleAction = null

        //当前播放的动作
        this.playing = null
        //当前播放的动作的后台信息
        this.playingItem = null

        //当前播放动作的器械的动作
        this.equipPlaying = null
        this.playMorph = true

        //所有的变型
        this.allMorphTargetDictionary = {}
        //当前正在播放的变型
        this.playingMorphTargetDictionary = []
        //变型是否需要反转变化
        this.reverseMorphPlay = false

        //点击过的肌肉的加载信息
        this.muscleAnimateData = {}
        this.pending = false
        this.cloneObj = []
        this.allMeshReference = {}
        this.morphTargetInfluences = {}

        //所有的肌肉
        this.allMuscle = []
        //染过色的肌肉的列表
        this.dyedRelateMeshList = []
        this.emissiveIntensityNow = 0

        // this.zhudongColor = new THREE.Color(0xff2a2a)
        this.zhudongColor = new THREE.Color(0xf85252)
        // this.xietongColor = new THREE.Color(0x5662ff)
        this.xietongColor = new THREE.Color(0x878eef)
        this.wendingColor = new THREE.Color(0xffea00)
        this.jiekangColor = new THREE.Color(0x90ff00)


        //运动时的肌肉分析
        this.wendingArr = []
        this.xietongArr = []
        this.zhudongArr = []
        this.jiekangArr = []
        this.fuzhuArr = []
        //加载过的所有起止点
        this.qizhidian = []
        //显示的起止点跟神经s
        this.visibleOriginsAndinsertionAndNerves = []
        this.slice = false
        this.sliceArr = []

        //乔治截图gif
        this.isExportGif = false
        // this.CSS2DObject = CSS2DObject

        //聚焦距离是包围盒的倍数
        this.distanceIntensity = 4
        this.currentClickObj = null,
        
        //显示是否完成
        this.isCheckDone = false
        this.rootObjectRadius = 9
        this.initCameraPositionZ = 6.5
        this.preMuscle = null

        //当前是否有一些模型关联展示的操作  比如显示器起止点神经支配  血供等   
        //如果是这个状态关闭肌肉点击
        this.muscleOption = false

        this.preMuscleArr = []
    }


    Object.assign(APPFN.prototype, {

        init: function (dom) {
            this.wrapDom = dom
            camera = new THREE.PerspectiveCamera(50, dom.getBoundingClientRect().width / dom.getBoundingClientRect().height, .05, 90)
            camera.position.z = 20
            // alert(window.devicePixelRatio)
            scene = new THREE.Scene()
            scene.updateMatrixWorld(true)
            scene.background = new THREE.Color(0xeeeeee)
            // if(this.isExportGif===false){
            //     scene.fog = new THREE.Fog(0xeeeeee, 5, 50)
            // }
            scene.add(camera)
            //动态加载的器械用
            sceneEquip = new THREE.Scene()
            this.sceneEquip = sceneEquip
            scene.add(sceneEquip)

            app.mixer = new THREE.AnimationMixer(scene)
            app.mixer2 = new THREE.AnimationMixer(sceneEquip)

            scene.add(new THREE.AmbientLight(0xffffff, .6))
            this.dirLight = new THREE.SpotLight(0xffffff,3.2,50)
            this.dirLight.decay=0.1
            this.dirLight.angle = Math.PI
            camera.add(this.dirLight)
            // this.directionalLight = new THREE.PointLight(0xc9d7ff, .3)
            // this.directionalLight.position.set(3, 2, 1)
            // this.directionalLight2 = new THREE.PointLight(0xfff5eb, .6)
            // this.directionalLight2.position.set(-3, 2, 1)
            // camera.add(this.directionalLight)
            // camera.add(this.directionalLight2)

            const domRect =  dom.getBoundingClientRect()
            renderer = new THREE.WebGLRenderer({
                antialias: isPc?true:false,
                alpha: false,
                //你捕获截图前调用一次渲染代码render就可以设置成false
                preserveDrawingBuffer: false
            })
            //renderer.shadowMap.enabled = true;
            renderer.setPixelRatio(window.devicePixelRatio)
            renderer.setSize(domRect.width, domRect.height)
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.type = THREE.PCFSoftShadowMap;
            // renderer.xr.enabled = true;
            // renderer.gammaInput = true
            renderer.outputEncoding = THREE.sRGBEncoding
            dom.appendChild(renderer.domElement)
            this.renderer = renderer
            console.log(renderer)

            // labelRenderer = new CSS2DRenderer()
            // labelRenderer.setSize(domRect.width, domRect.height )
            // labelRenderer.domElement.style.position = 'absolute'
            // labelRenderer.domElement.style.top = '0px'
            // dom.appendChild( labelRenderer.domElement )
            // this.labelRenderer = labelRenderer

            this.controls = new OrbitControls(camera, renderer.domElement)
            this.controls.maxDistance = 20
            this.controls.rotateSpeed = 1.5
            this.skinStander = new THREE.MeshPhongMaterial({
                color: 0xa0a0a0,
                depthWrite: false,
                opacity: .5
            })
            this.controls.addEventListener('change',()=>{})
            
            const ground = new THREE.Mesh(new THREE.PlaneGeometry(100, 100), new THREE.MeshBasicMaterial({
                color: 0xa9a9a9,
                depthWrite: false
            }))
            ground.rotation.x = -Math.PI / 2
            ground.receiveShadow = true
            ground.renderOrder = 20
            
            //app.ground = ground
            if(app.isExportGif===false){
                //scene.add(ground)
            }
            picker = new GPUPicker(THREE, renderer, scene, camera)

            this.camera = camera
            this.renderer = renderer
            this.scene = scene

            stats = new Stats()
            if(isDev){
                dom.appendChild( stats.dom )
            }
			
            let touchStart = false,
                startX = 0,
                startY = 0,
                nextX = 0,
                nextY = 0,
                moveDistance = 0
            dom.addEventListener('mousedown', function (event) {
                touchStart = true
                nextX = startX = event.pageX
                nextY = startY = event.pageY
                moveDistance = 0
            }, false)
            dom.addEventListener('mousemove', function (event) {
                if (!touchStart) return
                nextX = event.pageX
                nextY = event.pageY
                moveDistance = (nextY - startY) * (nextY - startY) + (nextX - startX) * (nextX - startX)
                if (moveDistance >= 30) {
                    touchStart = false
                }
            }, false)

            dom.addEventListener('mouseup', function (e) {
                if (!touchStart) return
                onClick(e)
                nextX = startX = moveDistance = nextY = startY = 0
                touchStart = false
            }, false)
            dom.addEventListener('mouseleave', function (e) {
                if (!touchStart) return
            }, false)
            if(isPc){
                dom.addEventListener('mousemove', function (e) {
                    mouseMovePick(e)
                }, false)
            }

            function animate() {
                requestAnimationFrame(animate)
                TWEEN.update()
                if(isDev){
                    stats.update()
                }
                renderer.render(scene, camera)
                // labelRenderer.render( scene, camera )
                //composer.render()
                // if(app.clickObj){
                //     const time = Date.now()*0.002
                //     const emissiveIntensity = Math.cos( time ) * 0.1+0.1
                //     app.emissiveIntensityNow = emissiveIntensity
                //     app.clickObj.material.emissiveIntensity = emissiveIntensity
                // }
                //器械单独播放
                if (app.mixer2) {
                    app.mixer2.update(clock2.getDelta())
                }
                if (app.mixer) {
                    app.mixer.update(clock.getDelta())
                    if (app.playing && !app.playIdle && !app.playing.paused) {
                        if (app.playing._loopCount === -1) {
                            app.reverseMorphPlay = false
                        } else {
                            app.reverseMorphPlay = app.playing._loopCount % 2 === 0 ? false : true
                        }
                
                        if(app.trackComponent){
                            let num = app.playing.time % app.playing._clip.duration / app.playing._clip.duration
                            app.trackComponent.setState({
                                num: parseInt(num * 100)
                            })
                            //播放变型
                            if (app.playMorph) {
                                app.handMorph(Number((num).toFixed(2)))
                            }
                        }
                    }
                }
            }

            animate()

            this.windowResize = (num) => {
                const {right} = app.indexComponent.state
                this.screen = {
                    h: window.innerHeight,
                    w: window.innerWidth
                }
                renderer.setSize(this.screen.w, this.screen.h)
                // labelRenderer.setSize(this.screen.w, this.screen.h)
                camera.aspect = this.screen.w / this.screen.h
                camera.updateProjectionMatrix()
                app.controls.update()
            }
            this.windowResize()
            window.addEventListener('resize', app.windowResize, false)
        },
        GLTFExporterFn:()=>{
            const exporter = new THREE.GLTFExporter()
            exporter.parse(
                app.scene,
                function ( gltf ) {
                    console.log( gltf );
                    let blob = new Blob([JSON.stringify(gltf)],{type:'application/json'})
                    const link=document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download='scene.gltf'
                    link.click()
                },
                // called when there is an error in the generation
                function ( error ) {
                    console.log( 'An error happened' );
                },
                {
                    onlyVisible: true,
                    truncateDrawRange: true,
                    maxTextureSize: Infinity,
                    animations: app.ttt,
                    includeCustomExtensions: false,
                    embedImages:false,
                    binary: false,
                    trs: false,
                    onlyVisible: true, //是否只导出可见物体
                })
        },
        darkScene:()=>{
            scene.background = new THREE.Color(0x212121)
            if(app.isExportGif===false){
                scene.fog = new THREE.Fog(0x212121, 10, 60)
            }
        },

        handMorph : (parcent) => {
            return
            if (app.playingMorphTargetDictionary && app.playingMorphTargetDictionary.length) {
                app.playingMorphTargetDictionary.map(item => {
                    item.bp_arr.map(bp => {
                        let num = app.reverseMorphPlay?parseInt((bp.bp_range.length-1) * (1 - parcent)) : parseInt((bp.bp_range.length-1) * parcent)
                        item.obj.morphTargetInfluences[bp.bp_index] = bp.bp_range[num]
                    })
                })
            }
        },

        //计算是否是双击 间隔设置成300ms 不是才能触发点击功能
        awiteDoubleClick(cb,obj) {
            this.doubleClick = true
            if (this.doubleClickTimer) clearInterval(this.doubleClickTimer)
            //双击计时定时器 
            this.doubleClickTimer = setTimeout(() => {
                this.doubleClick = false
                // cb(obj)
            }, 300)
        },

        //清除所有的a选中颜色
        resetSelectObjColor: function () {
            if (!app.selectObj.length) return
            let len = app.selectObj.length
            for (var i = 0; i < len; i++) {
                if (app.selectObj[i].length) {
                    for (var j = 0; j < app.selectObj[i].length; j++) {
                        app.changeSelectDye(app.selectObj[i][j], false)
                    }
                } else {
                    app.changeSelectDye(app.selectObj[i], false)
                }
            }
        },

        getOriginalEvent: function (event) {
            if (event && typeof event.originalEvent !== "undefined") {
                event = event.originalEvent
            }
            return event
        },

        getRatio: () => {
            var ratio = 0
            var screen = window.screen
            var ua = navigator.userAgent.toLowerCase()
            if (window.devicePixelRatio !== undefined) {
                ratio = window.devicePixelRatio
            } else if (~ua.indexOf('msie')) {
                if (screen.deviceXDPI && screen.logicalXDPI) {
                    ratio = screen.deviceXDPI / screen.logicalXDPI
                }
            } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
                ratio = window.outerWidth / window.innerWidth
            }
            if (ratio) {
                ratio = Math.round(ratio * 100)
            }
            return ratio
        },

        //鼠标滑动模型变色
        changeMMDye: function (o) {
            if (!app.mmObj) return
            var aa = app.mmObj.material
            if (o) {
                aa.color = this.mouseoverColor
            } else {
                //鼠标划走优先显示染过色的模型
                if(app.mmObj.dye){
                    aa.color = app.mmObj.dye
                }else{
                    if(app.mmObj.selectDye){
                        aa.color = app.mmObj.selectDye
                    }else{
                        aa.color = aa.originalColor
                    }
                }
            }
        },

        d2LookAtD1:(d2Mesh,d1Mesh)=>{
            if(d2Mesh&&d1Mesh){
                const d2MeshPos = app.calcSelectObjSphere([d2Mesh]).center
                const d1MeshPos = app.calcSelectObjSphere([d1Mesh]).center
                new TWEEN.Tween(app.controls.target)
                    .to({x: d1MeshPos.x,y: d1MeshPos.y,z: d1MeshPos.z}, 500)
                    .start()
                new TWEEN.Tween(app.camera.position)
                    .to({x: d2MeshPos.x,y: d2MeshPos.y,z: d2MeshPos.z}, 500)
                    .start()
                    .onUpdate(() => {
                        app.camera.lookAt(app.controls.target)
                    })
            }
        },

        calcSelectObjSphere:arr=>{
            if(!arr&&!arr.length) return 
            let objectArr = arr || this.selectObj 
            let object3D = new THREE.Object3D()
            for(let i = 0,len = objectArr.length;i<len;i++){
                object3D.children.push(objectArr[i])
            }
            let box3 = new THREE.Box3()
            box3.expandByObject(object3D)
            let sphere = new THREE.Sphere()
            box3.getBoundingSphere(sphere)
            return sphere
        },

        lookAtSkinMesh(skinmesh){
            // const sphere = this.calcSelectObjSphere(arr)
            // let {
            //     center,
            //     radius
            // } = sphere
            const finalPostion = app.getWoldPositionFromBoneTransform(skinmesh)

            new TWEEN.Tween(app.controls.target)
                .to({
                    x: finalPostion.x,
                    y: finalPostion.y,
                    z: finalPostion.z
                }, 500)
                .start()
                .onUpdate(() => {
                    app.camera.lookAt(app.controls.target)
                })
        },
        //穴位精灵图
        makeTextSprite:function(message,boundingSphere) {

            const canvas = document.createElement('canvas')
            canvas.height =  60
            const context = canvas.getContext('2d')
            const fontsize = 45
            context.font = fontsize + "px SimHei"
            const metrics = context.measureText(message)
            console.log(metrics)
            const textWidth = metrics.width
            let canvasWidth = textWidth+40
            canvas.width = canvasWidth
            // context.fillStyle = '#000';//设置填充颜色
            // context.rect(0,0,canvasWidth,30);
            // context.closePath();
            // context.fill()
            context.fillStyle = 'rgba(0,0,0,.7)';//设置填充颜色
            roundRect(0,0,canvasWidth,60,30)
            function roundRect(x, y, w, h, r) {
                if (w < 2 * r) r = w / 2;
                if (h < 2 * r) r = h / 2;
                context.beginPath();
                context.moveTo(x+r, y);
                context.arcTo(x+w, y, x+w, y+h, r);
                context.arcTo(x+w, y+h, x, y+h, r);
                context.arcTo(x, y+h, x, y, r);
                context.arcTo(x, y, x+w, y, r);
                context.closePath();
                context.fill()
            }
            context.fillStyle = "#fff"
            context.font = fontsize + "px SimHei"
            context.fillText(message, 20, 46)
        
            const texture = new THREE.Texture(canvas)
            texture.needsUpdate = true
            const spriteMaterial = new THREE.SpriteMaterial({ map: texture })
            const sprite = new THREE.Sprite(spriteMaterial)
            sprite.scale.set(canvasWidth/60*0.2, .2, .2)

            
            //sprite.material.depthTest = false
            //需要点击
            //sprite.material.depthWrite = false
            sprite.role = 'SPRITE'
            sprite.renderOrder = 28
            sprite.originOrder = 28

            sprite.material.originalColor = sprite.material.color.clone()

            return sprite
        },
        //独立显示 切换镜头到选中模型
        lookAtSelectObj(arr) {
            TWEEN.removeAll()
            const sphere = this.calcSelectObjSphere(arr)
            let {
                center,
                radius
            } = sphere
            app.camera.lookAt(center)

            //相机当前位置 当前距离
            let cameraPostionNow = app.camera.position.clone()
            let distanceNow = cameraPostionNow.distanceTo(center)

            //相机距离模型的最终距离
            let finnalDistance
            let distanceRate
            let finalPostion
            let finnalTarget

            if (!app.zoomIn) {
                finnalDistance = radius * app.distanceIntensity
                distanceRate = finnalDistance / distanceNow
                finalPostion = cameraPostionNow.lerp(center, 1 - distanceRate)
                finnalTarget = center
            } else {
                finnalDistance = app.rootObjectRadius
                //控制中心挪到模型中心点 app.initCenter
                //相机在原来的位置切垂直Y轴
                let verticalPos = new THREE.Vector3(cameraPostionNow.x, app.initCenter.y, cameraPostionNow.z)
                distanceNow = verticalPos.distanceTo(app.initCenter)
                distanceRate = finnalDistance / distanceNow
                //最终位置 垂直Y轴且延伸distanceRate倍数
                finalPostion = verticalPos.lerp(app.initCenter, 1 - distanceRate)
                finnalTarget = app.initCenter
            }
            
            //相机最终距离跟当前距离倍率
            new TWEEN.Tween(app.controls.target)
                .to({
                    x: finnalTarget.x,
                    y: finnalTarget.y,
                    z: finnalTarget.z
                }, 500)
                // .easing(TWEEN.Easing.Exponential.Out)
                .start()

            new TWEEN.Tween(app.camera.position)
                .to({
                    x: finalPostion.x,
                    y: finalPostion.y,
                    z: finalPostion.z
                }, 500)
                // .easing(TWEEN.Easing.Exponential.Out)
                .start()
                .onUpdate(() => {
                    app.camera.lookAt(app.controls.target)
                    // app.render()
                })
                // .onComplete(() => {
                //     app.calcZoomInState(arr)
                // })
        },
        
        fadeMaterail:(obj)=> {
            let aa = obj.material
            new TWEEN.Tween(aa)
            .to({ opacity: opa }, time)
            .start()
            .onComplete(() => {
                if (show === false) {
                    obj.visible = false
                }
            })
        },

        handleActionClick: function (code,fromHash) {
            //后退时候播放不用pushState
            // if(!fromHash){
            //     let state = {
            //         title: "title" ,
            //         url: '#action='+code
            //     }
            //     window.history.pushState(state, "title" , '#action='+code )
            // }
            app.progressComponent.startAction()
            if (app.pending) {
                Toast.show({ content:app.t('common.loading')})
                return
            }
            console.log('动作请求:'+code)
   
            axios.get(actionDetail, {params: {actionCode: code}}).then(function (response) {
                if (response.data.code === 'OK') {
                    let item = response.data.data
                    if (app.fixedObj) {
                        app.fixedObj.visible = false
                    }

                    app.playingItem = item
                    let action = app.allAction[item.fileName]
                    if (action) {
                        afterLoadAction(item)
                    } else {
                        app.pending = true
                        app.loadActionFileFromNet(item.filePath, () => {
                            afterLoadAction(item)
                            app.pending = false
                        }, (e) => {
                            console.log(item.filePath + e + '动作加载失败')
                            Toast.show({ content:app.t('common.networkError')})
                            app.pending = false
                        })
                    }
                }else{
                    Toast.show({ content:response.data.code})
                }
            })
            .catch(function (error) {
            })

            function afterLoadAction(item) {
                let rePlay = false
                if (app.playing) {
                    //当前播放的是同一个
                    if (app.playing._clip.name === item.fileName) {
                        rePlay = true
                    }
                    app.resetAllTempOption()
                }
                app.allMuscle.map(muscle => {
                    muscle.visible = false
                    muscle.material.opacity = 1
                    muscle.renderOrder = 6
                })
                //还原显示的起止点神经支配等
                app.hideOriginsAndinsertionAndNerves()
                //如果该动作有独立的器械动画 则先加载器械动作
                if (item.equip) {
                    if (rePlay) {
                        handleEquip()
                        //额外添加的器械要显示出来
                        if (app.allMeshReference[item.equip.modelName]) {
                            app.allMeshReference[item.equip.modelName].visible = true
                        }
                    } else {
                        app.sceneEquip.clear()
                        app.pending = true
                        app.loadEquipActionFileFromNet(item.equip, () => {
                            app.pending = false
                            handleEquip()
                        }, (e) => {
                            app.pending = false
                            console.log(item, e + '加载器械失败')
                        })
                    }
                } else {
                    handleEquip()
                    app.sceneEquip.clear()
                }
                function handleEquip() {
                    if (item.instrument && item.instrument.length) {
                        let instrumentArr = item.instrument
                        instrumentArr.map(item => {
                            if (app.equip[item.modelName]) {
                                app.equip[item.modelName].visible = true
                            } else {
                                console.log(item, '找不到模型')
                            }
                        })
                    }
                    app.getAndShowRelatedMesh(item)

                    app.trackComponent&&app.trackComponent.setState({
                        stop:false
                    })
                    if(item.fixation){
                        if (app.fixedObj) {
                            app.fixedObj.visible = true
                        }
                    }
                    app.playActionByName(item)
                    app.progressComponent&&app.progressComponent.setState({
                        data:item,
                        playError: false,
                        playing: true,
                        fixationCheck:true,
                        stop:false,
                        staticPos:app.playing._clip.duration>0.2?false:true
                    })
  
                }
            }
        },

        //协同肌之类的变色并且显示出来
        getAndShowRelatedMesh: function (obj) {
            //如果是显示皮肤，则不显示相关肌肉
            let visible =  app.sliderComponent.state.layerIndex==-1?false:true
            app.allMuscle.map(item => {
                let id = String(item.uuid)
                if (obj.wending && obj.wending.split(',').indexOf(id) >= 0) {
                    app.dyedRelateMeshList.push(item)
                    app.wendingArr.push(item)
                    item.visible = visible
                }

                if (obj.xietong && obj.xietong.split(',').indexOf(id) >= 0) {
                    app.xietongArr.push(item)
                    app.dyedRelateMeshList.push(item)
                    item.visible = visible
                }

                if (obj.zhudong && obj.zhudong.split(',').indexOf(id) >= 0) {
                    app.zhudongArr.push(item)
                    app.dyedRelateMeshList.push(item)
                    item.visible = visible
                }

                if (obj.jiekang && obj.jiekang.split(',').indexOf(id) >= 0) {
                    app.jiekangArr.push(item)
                    app.dyedRelateMeshList.push(item)
                    item.visible = visible
                }
                if (obj.jiekang && obj.jiekang.split(',').indexOf(id) >= 0) {
                    app.jiekangArr.push(item)
                    app.dyedRelateMeshList.push(item)
                    item.visible = visible
                }

                if (obj.fuzhu && obj.fuzhu.split(',').indexOf(id) >= 0) {
                    app.fuzhuArr.push(item)
                    item.material.opacity = .4
                    item.renderOrder = 8
                    item.visible = true
                }
            })
            if (app.progressComponent) {
                app.progressComponent.setState({
                    existActive: !!app.dyedRelateMeshList.length,
                    visibleZhudong:true,
                    visibleXietong:true,
                    visibleWending:true,
                    visibleKJiekang:true,
                    clearColor:false
                })
            }
            //
            !!app.dyedRelateMeshList.length&&app.toggleDyeRelatedMesh(true)
        },

        toggleDyeRelatedMesh:(flag)=>{
            const {zhudongArr,wendingArr,xietongArr,jiekangArr} = app
            wendingArr.length>0&wendingArr.map(item=>{
                const mat = item.material
                const {wendingColor} = app
                mat.color = flag?wendingColor:mat.originalColor
                item.dye = flag?wendingColor:null
            })
            xietongArr.length>0&xietongArr.map(item=>{
                const mat = item.material
                const {xietongColor} = app
                mat.color = flag?xietongColor:mat.originalColor
                item.dye = flag?xietongColor:null
            })
            zhudongArr.length>0&zhudongArr.map(item=>{
                const mat = item.material
                const {zhudongColor} = app
                mat.color = flag?zhudongColor:mat.originalColor
                item.dye = flag?zhudongColor:null
            })
            jiekangArr.length>0&jiekangArr.map(item=>{
                const mat = item.material
                const {jiekangColor} = app
                mat.color = flag?jiekangColor:mat.originalColor
                item.dye = flag?jiekangColor:null
            })
          
        },

        centerScene: (cb) => {
            if (app.initCenter) {
                new app.TWEEN.Tween(app.camera.position)
                    .to({
                        x:3,
                        y: app.initCenter.y+1.2,
                        z: app.initCameraPositionZ
                    }, 600)
                    .easing(app.TWEEN.Easing.Linear.None)
                    .start()
                new app.TWEEN.Tween(app.controls.target)
                    .to({
                        x: app.initCenter.x,
                        y: app.initCenter.y,
                        z: app.initCenter.z,
                    }, 600)
                    .easing(app.TWEEN.Easing.Linear.None)
                    .start()
                    .onUpdate(() => {
                        app.camera.lookAt(app.controls.target)
                    })
                    .onComplete(()=>{
                        if(typeof(cb)==='function'){
                            cb()
                        }
                    })
            }
        },

        //还原已经显示的起止点  还协同肌等材质颜色
        resetAllTempOption: () => {
            if (app.tempMeshList.length) {
                app.tempMeshList.map(item => {
                    item.visible = false
                })
                app.tempMeshList.length = 0
            }
            if (app.dyedRelateMeshList.length) {
                app.dyedRelateMeshList.map(item => {
                    let mat = item.material
                    mat.color = mat.originalColor
                    item.dye = null
                })
                app.dyedRelateMeshList.length = 0
            }
            app.wendingArr = []
            app.xietongArr = []
            app.zhudongArr = []
            app.jiekangArr = []
            app.fuzhuArr = []
            if (app.progressComponent) {
                app.progressComponent.setState({
                    showActiveMuscle: true
                })
            }
            //隐藏所有正在显示显示的的器械
            for (let i in app.equip) {
                app.equip[i].visible = false
            }
        },

        buildAllXmlGroup: function (parent,allObj) {
            const rootRroup = new THREE.Group()
            rootRroup["objName"] = 'rootGroup'
            rootRroup.root = true
            searchLoop(allObj, rootRroup)
            function searchLoop(arr, group) {
                if (arr.length) {
                    arr.forEach(function (child) {
                        let _group = new THREE.Group()
                        _group['name'] = 'Group_' + child["objName"]
                        _group["objName"] = child["objName"]
                        _group["name"] = child["name"]

                        if(child["qzdFuzhu"]){
                            _group["qzdFuzhu"] = child["qzdFuzhu"]
                        }
                        if (child["note"]) {
                            _group["note"] = child["note"]
                        }
                        if (child["layer"]) {
                            _group["layer"] = child["layer"]
                        }
                        //起止点信息
                        if (child["qidianNote"]) {
                            _group["qidianNote"] = child["qidianNote"]
                        }
                        if (child["zhidianNote"]) {
                            _group["zhidianNote"] = child["zhidianNote"]
                        }
                        if (child["shenjingNote"]) {
                            _group["shenjingNote"] = child["shenjingNote"]
                        }
                        //血供
                        if (child["Bloodsupply"]) {
                            _group["Bloodsupply"] = child["Bloodsupply"]
                        }
                        //力线
                        if (child["JLXZY"]) {
                            _group["JLXZY"] = child["JLXZY"]
                        }

                        //起点
                        if (child["startPoint"]) {
                            _group["startPoint"] = child["startPoint"]
                        }
                        //止点
                        if (child["deadCenter"]) {
                            _group["deadCenter"] = child["deadCenter"]
                        }
                        //神经支配
                        if (child["Innervation"]) {
                            _group["Innervation"] = child["Innervation"]
                        }

                        
                        //链接到哪个肌肉的运动
                        // if (child["dataId"]) {
                        //     _group["hasData"] = true
                        // }
                        _group["hasData"] = true
                        if (child["id"]) {
                            _group["uuid"] = child["id"]
                        }
                        if (child['rol']) {
                            _group["rol"] = child['rol']
                        } else {
                            _group["rol"] = group['rol']
                        }
                        //检测续下重名情况
                        if (app.groupLinkToMesh[child["objName"]]) {
                            console.log(child["objName"])
                        }
                        app.groupLinkToMesh[child["objName"]] = _group
                        group.add(_group)
                        if (child.arr && child.arr.length) {
                            return searchLoop(child.arr, _group)
                        }
                    })
                }
            }
            parent.add(rootRroup)

        },

        //处理默认点击的是什么类型动作
        handleFntype: (axiosData) => {
            switch (app.fnType) {
                case 'inform':
                    app.muscleComponent.typeClick('inform')
                    break
                case '1':
                    if (axiosData.function) {
                        app.muscleComponent.typeClick('1')
                    } else if(axiosData.train){
                        app.muscleComponent.typeClick('2')
                    }else{
                        app.muscleComponent.typeClick("3")
                    }
                    break
                case '2':
                    if (axiosData.train) {
                        app.muscleComponent.typeClick('2')
                    } else if(axiosData.function) {
                        app.muscleComponent.typeClick('1')
                    }else{
                        app.muscleComponent.typeClick("3")
                    }
                    break
                case '3':
                    //默认点击功能动作
                    if (axiosData.stretching) {
                        app.muscleComponent.typeClick("3")
                    } else if(axiosData.function) {
                        app.muscleComponent.typeClick('1')
                    }else{
                        app.muscleComponent.typeClick('2')
                    }
                    break
                default:
                    app.muscleComponent.typeClick('inform')
                    break
            }
        },


        cutScreen: () => {
            app.renderer.render(app.scene, app.camera)
            let imageData
            let rendererCanvas = app.renderer.domElement
            imageData = rendererCanvas.toDataURL()
            let width = parseInt(rendererCanvas.style.width)
            let height = parseInt(rendererCanvas.style.height)
            let drawCanvas = app.drawerComponent.state.CanvasDom
            let oCanvas = document.createElement('canvas')
            let oCanvasContext = oCanvas.getContext("2d")
            oCanvas.width = width
            oCanvas.height = height
            let imgDom = document.createElement('img')
            imgDom.src = imageData
            imgDom.onload = () => {
                oCanvasContext.drawImage(imgDom, 0, 0, width, height)
                oCanvasContext.drawImage(drawCanvas, 0, 0, width, height)
                let url = oCanvas.toDataURL('image/png')
                let blob = dataUrlToBold(url)
                let obj_url = URL.createObjectURL(blob)
                let a = document.createElement('a')
                let event = new MouseEvent('click')
                let num = Math.floor(Math.random() * 100000)
                if(app.isExportGif){
                    a.download = app.playing._clip.name
                }else{
                    a.download = '3Dbody-' + num
                }
                a.href = obj_url
                a.dispatchEvent(event)
            }

            function dataUrlToBold(url) {
                let arr = url.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bStr = atob(arr[1]),
                    n = bStr.length,
                    u8arr = new Uint8Array(n)
                while (n--) {
                    u8arr[n] = bStr.charCodeAt(n)
                }
                return new Blob([u8arr], {
                    type: mime
                })
            }

        },

        //还原显示的起止点神经支配等  主要是隐藏
        hideOriginsAndinsertionAndNerves:()=>{
            if(app.visibleOriginsAndinsertionAndNerves.length){
                app.visibleOriginsAndinsertionAndNerves.map(item=>{
                    item.visible=false
                    item.children.length = 0
                }).length=0
                app.muscleComponent.updateData({
                    showQizhi:false,
                    showShenjing:false
                })
            }
        },

        clickOption: function (mesh,fromHash,fromPre,fromPlayingNow) {
            //后退时候点击不用pushState
            // if(!fromHash){
            //     let state = {
            //         title: "title" ,
            //         url: '#mesh='+mesh.name
            //     }
            //     window.history.pushState(state, "title" , '#mesh='+mesh.name )
            // }
            //正在加载中就不让加载了
            // if (app.pending) {
            //     Toast.show({ content:app.t('common.loading')})
            //     return
            // }
            mesh.visible = true
            app.indexComponent.setState({
                selectName: null
            }) 
            if (app.clickObj) {
                // if (app.clickObj === mesh) {
                //     app.clickObj.material.color = app.selectColor
                //     app.clickObj.selectDye = app.selectColor
                //     if(app.muscleOption) return
                //     app.panel.snapTo(1)
                //     //如果当前显示起止点等
                //     app.muscleComponent.setPanel(true)
                //     return
                // }
                if (app.clickObj.dye) {
                    app.clickObj.material.color = app.clickObj.dye
                } else {
                    app.clickObj.material.color = app.clickObj.material.originalColor
                }
                app.clickObj.selectDye = null
                app.clickObj = null
            }
            app.clickObj = mesh
            if (!app.clickObj.dataParent) {
                return
            }
            app.clickObj.material.color = app.selectColor
            app.clickObj.selectDye = app.selectColor
            console.log('clickObj:', app.clickObj)
            
            //当前正在播放且点击肌肉 则更新信息
            if(app.playingNow&&!fromPlayingNow){
                app.progressComponent.setClickMuscle(mesh)
                return
            }
            //如果当前显示起止点等
            if(app.muscleOption) return
            //如果这模型有动作信息
            if (app.clickObj.dataParent && app.clickObj.dataParent.hasData) {
                // app.indexComponent.toggleRight(true)
                app.preMuscle = app.clickObj
                //记录点击到有信息的肌肉
                if(!fromPre){
                    if(app.preMuscleArr.length){
                        if(app.preMuscleArr[0]!==mesh){
                            app.preMuscleArr.unshift(mesh)
                        }
                    }else{
                        app.preMuscleArr.unshift(mesh)
                    }
                }
                app.panel.snapTo(1)
                app.muscleComponent.show(true)
                app.muscleComponent.setPanel(true)
                app.indexComponent.setState({
                    allLoading: true,
                })
                app.selectMuscle = app.clickObj
                let id = app.clickObj.dataParent.uuid
                let axiosDataById = app.muscleAnimateData[id]
                //加载过信息
                if (axiosDataById) {
                    app.muscleComponent.updateData({
                        allLoading: false,
                        data: axiosDataById,
                        objDataParent:app.clickObj.dataParent,
                        selectName: app.clickObj.dataParent.name
                    })
                    //默认点击的动作类型
                    app.handleFntype(axiosDataById)
                } else {
                    //检查对应的肌肉动作信息是否已经加载过
                    if (id && !app.pending) {
                        app.pending = true
                        //先显示panel才有app.muscleComponent
                        setTimeout(() => {
                            app.muscleComponent.updateData({
                                allLoading: true,
                            })
                        })
                        axios.get(muscleInfo, {params: {muscleId: id}}).then(function (response) {
                            if (response.data.code === 'OK') {
                                let data = response.data.data
                                console.log(data)
                                app.muscleComponent.updateData({
                                    selectName: app.clickObj.dataParent.name,
                                    allLoading: false,
                                    data: data,
                                    objDataParent:app.clickObj.dataParent
                                })
                                //默认点击的动作类型
                                app.handleFntype(data)
                                app.muscleAnimateData[id] = data
                                app.clickObj.hasLoad = true
                                app.pending = false
                            }else{
                                Toast.show({ content:response.data.code})
                            }
                        }).catch(function (error) {
                            console.log(error)
                            app.pending = false
                            app.muscleComponent.updateData({
                                allLoading: true,
                            })
                        })
                    }
                }
                //没有动作就只在上方显示名称
            }
        },

        handleMM: (mesh) => {
            if (app.mmObj && app.mmObj !== mesh) {
                app.changeMMDye(false)
            }
            app.mmObj = mesh
            app.changeMMDye(true)
        },

        getWoldPositionFromBoneTransform:(skinMesh)=>{
            const target = new THREE.Vector3()
            target.fromBufferAttribute( skinMesh.geometry.attributes.position, Math.ceil(skinMesh.geometry.attributes.skinIndex.count/2) )
            skinMesh.boneTransform( 1, target )
            return target
        },
        calcMeshPosition: function (Mesh) {
            Mesh.updateMatrixWorld(true)
            Mesh.matrixWorldNeedsUpdate = true
            Mesh.geometry.computeBoundingBox()
            var boundingBox = Mesh.geometry.boundingBox
            var position = new THREE.Vector3()
            position.subVectors(boundingBox.max, boundingBox.min)
            position.multiplyScalar(0.5)
            position.add(boundingBox.min)
            position.applyMatrix4(Mesh.matrixWorld)
            return position
        },
    })


    //鼠标点击模型事件
    function onClick(event) {
        if(!app.loadComplete) return
        if (app.skinMesh && app.skinMesh.material.opacity === 1) return

        if (!event.clientX && event.changedTouches) {
            event = event.changedTouches[0]
        }

        let aimX = event.offsetX
        // if (app.jingxiang) {
        //     let offsetX = event.offsetX
        //     let centerX = app.wrapDom.getBoundingClientRect().width / 2
        //     console.log(centerX)
        //     console.log(offsetX)
        //     if (offsetX > centerX) {
        //         aimX = centerX - (offsetX - centerX)
        //     } else {
        //         aimX = centerX + (centerX - offsetX)
        //     }
        //     console.log(aimX)
        // }
        app.mousemoveComponent&&app.mousemoveComponent.setState({mousemoveName: null})
        const objectId = picker.pick(aimX * window.devicePixelRatio, event.offsetY * window.devicePixelRatio)
        //选中模型    
        if (objectId && objectId > 0 && app.groupLinkToId[objectId]) {
            const obj = app.groupLinkToId[objectId]
            //隐藏模型点击帮助
            app.interactHelpComponent.closeHelp()
            if (app.slice) {
                obj.visible = false
                app.sliceArr.push(obj)
                return
            }
            if (!obj.dataParent) return
            if(app.doubleClick){
                if(app.currentClickObj&&app.currentClickObj===obj){
                    app.lookAtSelectObj([obj])
                    return
                }
            }else{
                app.awiteDoubleClick()
            }
            app.clickOption(obj)
            app.currentClickObj = obj          
            //没选中任何模型    
        } else {
            //当前正在播放且点击肌肉 则更新信息
            if(app.playingNow){
                app.progressComponent.setClickMuscle(null)
            }
            if (app.mmObj) { app.changeMMDye(false)}
            if (app.clickObj) {
                if(app.clickObj.selectDye){
                    app.clickObj.selectDye = null
                }
                if (app.clickObj.dye) {
                    app.clickObj.material.color = app.clickObj.dye
                } else {
                    app.clickObj.material.color = app.clickObj.material.originalColor
                }
                app.clickObj = null
                if(app.muscleOption) return
                app.panel.snapTo(1)
                app.muscleComponent.setPanel(true)
            }else{
                //第二次点击空白处隐藏信息面板  如果当前没播放动作则隐藏操作栏目
                if(!app.playing){
                    app.muscleComponent.show(false)
                    app.panel.snapTo(1)
                    app.muscleComponent.setPanel(false)
                }
            }
            app.indexComponent.setState({
                selectName: null
            })
            app.mmObj = null
        }
    }

    function mouseMovePick(event) {
        if(!app.loadComplete) return
        //正在加载中就不让加载了
        if (app.pending) return
        if (app.skinMesh && app.skinMesh.material.opacity === 1) return
        if (!event.clientX && event.changedTouches) {
            event = event.changedTouches[0]
        }
        let aimX = event.offsetX
        // if (app.jingxiang) {
        //     let offsetX = event.offsetX
        //     let centerX = app.wrapDom.getBoundingClientRect().width / 2
        //     if (offsetX > centerX) {
        //         aimX = centerX - (offsetX - centerX)
        //     } else {
        //         aimX = centerX + (centerX - offsetX)
        //     }
        // }
        const objectId = picker.pick(aimX * window.devicePixelRatio, event.offsetY * window.devicePixelRatio)
        let obj
        //没选中任何模型    
        if (objectId && objectId > 0 && app.groupLinkToId[objectId]) {
            obj = app.groupLinkToId[objectId]
            if (!obj.dataParent) return 
            let name = obj.dataParent.name
            app.mousemoveComponent&&app.mousemoveComponent.setState({
                mousemoveName: name,
                hasData:!!obj.hasData,
                pos: {
                    x: event.offsetX + 25,
                    y: event.offsetY + 20
                }
            })
            //清除上一个划过模型的颜色
            if (app.mmObj&&app.mmObj!==obj) {
                app.changeMMDye(false)
            }
            app.mmObj = obj
            app.changeMMDye(true)
            //没选中任何模型    
        } else {
            if (app.mmObj) {
                app.changeMMDye(false)
            }
            app.mousemoveComponent.setState({
                mousemoveName: null
            })
            app.mmObj = null
        }
    }

    const app = new APPFN()
    window.app = app
    return app

})()


export default app

