import { combineReducers } from 'redux'

//控制 userData 的reducer
import { CollectDataReducer } from './redux.collect'
import { PlayingReducer } from './redux.playing'

//合并所有reducer并且返回
const reducers = combineReducers({
    CollectDataReducer,
    PlayingReducer
})

export default reducers