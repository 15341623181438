import i18n from 'i18next';
import {
    initReactI18next
} from 'react-i18next';
import app from './pages/home/src/app.js'
import { is3dbody,isDev,isPc } from './pages/home/config.js';
import translation_en from './i18n/en.json';
import translation_zh from './i18n/zh.json';


//3dbody上部署强制是cn
if(is3dbody){
    app.lang = 'cn'
}else{
    //muscle1上PC部署只需要从localStorage获取
    if(isPc){
        let lang = localStorage.getItem('language')
        app.lang = !!lang?lang:'en'
    }else{
        if(isDev){
            let lang = localStorage.getItem('language')
            app.lang = !!lang?lang:'en'
        }
    }
}

const resources = {
    en: {
        translation: translation_en,
    },
    cn: {
        translation: translation_zh,
    },
}
app.changeLang=()=>{
    i18n
        .use(initReactI18next)
        .init({
            resources,
            lng: app.lang,
            interpolation: {
                escapeValue: false,
            },
        })
}
app.getLangFormApp = false
app.changeLang()

export default i18n;