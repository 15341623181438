import React from "react"
import { Button,Space,Divider} from "antd-mobile"
import app from "../../../pages/home/src/app.js"
import { withTranslation } from "react-i18next"
import { LeftOutline } from 'antd-mobile-icons'
import "./sj.less"

class Sj extends React.Component {
    state = {
        //显示肌肉信息的高度
        show:false,
        index:null,
        list:[]
    }

    componentDidMount() {
        app.sjComponent = this
    }

    //显示起止点
    enableMesh = (flag) => {
        if (!app.selectMuscle) return
        // app.progressComponent.closePanel()
        const handleNevers =()=>{
            const Innervation = app.selectMuscle.dataParent.Innervation
            if (Innervation === undefined) return
            app.hideOriginsAndinsertionAndNerves()
            if (flag) {
                app.handleResetPrePlaying()
                app.resetAllTempOption()
                if(app.progressComponent){
                    app.progressComponent.setState({
                        show: false,
                    })
                }
                if (app.fixedObj) {
                    app.fixedObj.visible = false
                }
                app.allMuscle.map((item) => {
                    item.visible = false
                    item.material.opacity = 1
                    item.renderOrder = 6
                })
                app.selectMuscle.visible = true
                app.selectMuscle.material.opacity = 0.3
                app.selectMuscle.renderOrder = 8
    
                let innervationArr = Innervation.split(",")
                let group = []
                if (innervationArr.length) {
                    innervationArr.map((item) => {
                        let obj = app.allMeshReference[item]
                        if (obj) {
                            obj.visible = true
                            // obj.material.depthTest = false
                            obj.material.emissive = app.neversColor
                            // obj.material.emissiveIntensity = 0.3
                            group.push(obj)
                            app.visibleOriginsAndinsertionAndNerves.push(obj)
                        }
                    })
                }
                console.log(group)
                this.setState({
                    list:group
                })
                app.lookAtSelectObj(group)
            } else {
                app.sliderComponent.resetSlider()
                app.centerScene()
            }
        }
        if(app.selectMuscle.sj){
            handleNevers()
        }else{
            app.loadSJQZD(app.selectMuscle,'sj',()=>{
                handleNevers()
            }, ()=>{
                console.log(app.selectMuscle,'神经加载失败哎')
            })
        }
    }


    itemClick=(obj,i)=>{
        app.lookAtSelectObj([obj])
        app.clickOption(obj)
        this.setState({
            index:i
        })
    }

    enablePanel=(flag)=>{
        app.muscleOption = flag
        this.setState({
            show:flag
        })
        app.DirectionComponent.setState({
            hideLayerChange:flag
        })  
        this.enableMesh(flag)
        app.panel.snapTo(1)
        app.muscleComponent.setPanel(!flag)
        app.muscleComponent.show(!flag)
    }


    render() {
        const {show,list,index} = this.state
        const { t} = this.props
        return (
            <>
                {
                    show?<div className="sj-wrap">
                        <div className="sj-item-wrap">
                            {
                                list.map((item,i)=>{
                                    return <Button fill={index===i?'solid':'none'}  key={i}
                                    onClick={this.itemClick.bind(this,item,i)}>
                                        {item.dataParent.name}
                                    </Button>
                                })
                            }
                        </div>
                        <div className="sj-close-wrap">
                            <Button onClick={()=>{this.enablePanel(false)}}>
                                <Space>
                                        <LeftOutline/>
                                        <span>{t("common.return")}</span>
                                </Space>
                            </Button>
                        </div>
                    </div>:null
                }
            </>
        )
    }
}

export default withTranslation()(Sj);
