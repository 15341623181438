//import {  Slider } from 'antd-mobile';\
import React from 'react'
import app from '../../pages/home/src/app.js'
import { withTranslation } from "react-i18next"
import './slider.less'

const masks = {
    0: '0°C',
    26: '26°C',
    37: '37°C'
}

class SliderCom extends React.Component {

    state = {
        layerLenth:0,
        index:0,
        jingxiang:false,
        num:15,
        marks:{masks}
    }

    componentDidMount() {
        app.sliderComponent = this
    }

    sliderChange=(num)=>{
        const {index} = this.state
        let tampIndex  = parseInt(7*(num/100))
        tampIndex = tampIndex - 1
        if(tampIndex!==index){
            this.setState({
                index:tampIndex,
                num:num
            })
            this.handeLayerChange(tampIndex)
        }
    }
    
    //还原到当前滑动的肌肉
    resetSlider=()=>{
        const {index} = this.state
        this.handeLayerChange(index)
    }

    handeLayerChange=(index,reset)=>{
        if(reset){
            index = this.state.index
        }
        this.resetInformOpt()
        for (let i = 0; i < app.objects.length; i++) {
            let layer = app.objects[i].layer
            if(index<0){
                app.objects[i].visible = false
            }else{
                if(layer<=index){
                    app.objects[i].visible = false
                }else{
                    app.objects[i].visible = true 
                    app.objects[i].material.opacity = 1
                }
            }
        }
        
        //皮肤 
        if(index>=0&&app.skinMesh){
            // app.skinMesh.renderOrder = 0
            // app.skinMesh.material.opacity  = .7
            // app.skinMesh.material.depthWrite = false
            //app.skinMesh.material = app.customShaderMaterial
            // app.skinMesh.castShadow = false
            // app.skinMesh.receiveShadow = false
            // app.skinMesh.material.needsupdate = true
            //app.skinMesh.material.map = app.skinImg
            //console.log(app.skinMesh)
            //app.skinMesh.material.depthTest = true

            app.skinMesh.material = app.opacityMat
            app.skinMesh.renderOrder = 0
            if(app.neikuMesh){
                app.neikuMesh.visible = false
            }
            if(app.M_Malelashes){
                app.M_Malelashes.visible = false
            }
            // app.skinMesh.visible = false
        }else{
            //app.skinMesh.material.needsupdate = true
            if(app.skinMesh){
                //app.skinMesh.material = app.skinMesh.originalMaterial
                // app.skinMesh.castShadow = true
                // app.skinMesh.receiveShadow = true
                // app.skinMesh.material.opacity  = 1
                //app.skinMesh.material.depthTest = false
                //app.skinMesh.material.depthWrite = true
                //app.skinMesh.material.map = app.skinMesh.originalMap
                app.skinMesh.material = app.skinMesh.originelMat
                app.skinMesh.material.needsupdate = true
            }
            if(app.neikuMesh){
                app.neikuMesh.visible = true
            }
            if(app.M_Malelashes){
                app.M_Malelashes.visible = true
            }
        }
        if(index<1){
            if(app.allMeshReference['M_Rectus_abdominis_L']){
                app.allMeshReference['M_Rectus_abdominis_L'].visible = false
            }
            if(app.allMeshReference['M_Transversus_abdominis_L']){
                app.allMeshReference['M_Transversus_abdominis_L'].visible = false
            }
            if(app.allMeshReference['M_Internal_oblique_L']){
                app.allMeshReference['M_Internal_oblique_L'].visible = false
            }
            //app.allMeshReference['M_Rectus_abdominis_R'].visible = false
            //app.allMeshReference['M_Transversus_abdominis_R'].visible = false
            //app.allMeshReference['M_Internal_oblique_R'].visible = false
        }
        if(index<2){
            if(app.allMeshReference['M_Transversus_abdominis_L']){
                app.allMeshReference['M_Transversus_abdominis_L'].visible = false
            }
            // app.allMeshReference['M_Transversus_abdominis_R'].visible = false
        }

    }

    //还原显示神经 起止点 离线等
    resetInformOpt=()=>{
        //还原速隐
        app.sliceArr = []
        //还原显示的起止点神经支配等
        app.hideOriginsAndinsertionAndNerves()
        if(app.selectMuscle){
            app.selectMuscle.material.opacity = 1
            app.selectMuscle.renderOrder = 8
            //显示起止点的时候还原起止点辅助的透明度
            const { showQizhi } =  app.muscleComponent
            if(showQizhi){
                const qzdFuzhu = app.selectMuscle.dataParent.qzdFuzhu
                if(qzdFuzhu){
                    qzdFuzhu.split(',').forEach(item=>{
                        let obj = app.scene.getObjectByName(item)
                        if(obj){
                            obj.material.opacity = 1
                        }
                    })
                }
            }
        }
    }

    tapLayer=(type)=>{
        let {index} = this.state
        
        if(type&&type==='add'){
            index++
            if(index>5){
                index = 5
            }
        }else{
            index--
            //-1是显示皮肤
            if(index<-1){
                index = -1
            }
        }
        this.handeLayerChange(index)
        this.setState({
            index:index
        })

    }

    toggleJing=()=>{
        const {jingxiang} = this.state
        this.setState({
            jingxiang:!jingxiang
        })
        app.jingxiang = !jingxiang
        app.renderer.domElement.style.transform = `scaleX(${jingxiang?'1':'-1'})`
    }


    render() {
        const {jingxiang,num,masks} = this.state
        const { t } = this.props

        return (
            // className = {this.props.show?'slider-wrap':'slider-wrap hiden'}
            <>

            {/* <div onClick={this.toggleJing} className={jingxiang?'jingiang-wrap active':'jingiang-wrap'}>
                    <div className='jingxiang-icon'></div>
                    <p>{t('slider.mirror')}</p>
                </div> 
            */}
            {/*                 
                <div onClick={this.tapLayer.bind(this,'sub')} id='subt' className='slider-tap sub'></div>
                
                <Slider
                    min = {0}
                    max = {100}
                    style={{ '--fill-color': '#ffdd00' }}
                    value={num}
                    tooltip={{ open: false }} 
                    vertical = {true}  
                    reverse = {true}
                    // masks={masks}
                    // dots={true}
                    onChange={(num) => {
                        //console.log(num)
                        this.sliderChange(num)
                    }}
                />
                <div onClick={this.tapLayer.bind(this,'add')} className='slider-tap add'></div>
            */}
                
            </>
            
        )
    }
}

export default withTranslation()(SliderCom)