import app from '../../pages/home/src/app.js'

function initCanvasDraw() {
    
    let oCanvas,
        context,
        W,
        H,
        stepArr = [],
        scop = this,
        startX,
        startY,
        prevX,
        prevY
    const _this  = this

    function canvasResize() {
        const dom = document.getElementById('webgl')
        //没有他初始化好oCanvas宽高设置都有问题
        W = dom.getBoundingClientRect().width
        H = dom.getBoundingClientRect().height
        oCanvas.width = W
        oCanvas.height = H
    }

    this.init = function(canvas, component) {
        console.log(canvas)
        oCanvas = canvas
        context = oCanvas.getContext("2d")
        this.component = component
    }

    this.resetCanvas = ()=>{
        this.component.setState({
            canClear:false
        })
        context.clearRect(0, 0, W, H);
        stepArr = []
        stepArr.push(context.getImageData(0, 0, W, H))
    }

    this.returnPre =()=>{
        if (stepArr.length <=1 ) return
        stepArr.splice(stepArr.length - 1, 1)
        if (stepArr.length <= 1) {
            this.component.setState({
                canClear:false
            })
        }
        let imgData = stepArr[stepArr.length - 1]
        context.putImageData(imgData, 0, 0)
    }

    this.beginDraw = function(flag) {
        this.component.setState({
            canClear:false,
            showCanvas:flag,
            showOption:flag
        })
        
        //画点东西
        if (flag) { //打开画笔功能
            canvasResize()
            app.controls.enabled = false
            stepArr.push(context.getImageData(0, 0, W, H))
            activate()
            // app.indexComponent.toggleRight(false)
            // app.indexComponent.setState({
            //     showRightBt:false
            // })
            setTimeout(() => {
                canvasResize()
            }, 500)
        } else {
            stepArr = []
            app.controls.enabled = true
            dispose()
        }
        // //隐藏左侧展开开关按钮
        // app.indexComponent.setState({
        //     showRightBt:!flag
        // })
    }

    let ifDraw = false

    let touchStart = (ev)=> {
        ev.preventDefault()
        ev.stopPropagation()
        //开始画的时候收起工具栏
        this.component.setState({
            showOption:false
        })
        var touches = app.getOriginalEvent(ev).touches
        if (touches && touches.length === 1) {
            ev = touches[0]
        }
        ifDraw = true
        prevX = ev.clientX
        prevY = ev.clientY
        startX = ev.clientX
        startY = ev.clientY

        context.beginPath()
        const {fnType,lineWidth,opacity,color} = this.component.state
        //context.globalCompositeOperation = "source-over"
        context.lineWidth = lineWidth
        context.globalAlpha = opacity/100
        context.fnType = fnType
        context.strokeStyle = color
        // context.beginPath();
        // context.moveTo(startX,startY);
    }

    let touchMove = (ev) => {
        ev.preventDefault()
        ev.stopPropagation()
        if(!ifDraw) return
        const touches = app.getOriginalEvent(ev).touches
        if (touches && touches.length === 1) {
            ev = touches[0]
        }
        const {clientX,clientY} = ev
        //单纯画笔时候不清画布
        if(context.fnType!=="brush"&&context.fnType!=="eraser"){
            context.clearRect(0,0,W,H);
            if(stepArr.length!==0){
                let imgData = stepArr[stepArr.length - 1]
                context.putImageData(imgData, 0, 0)
            }
        }
    
        switch (context.fnType) {
            case 'brush':
                context.beginPath()
                context.lineCap="round";
                context.moveTo(prevX, prevY)
                context.lineTo(clientX,clientY)
                context.stroke()
                break;
            case 'line':
                context.lineCap="round";
                context.beginPath();
                context.moveTo(startX,startY);
                context.lineTo(clientX,clientY);
                context.stroke();
                break;
            case 'rectangle':
                context.beginPath();
                context.rect(startX,startY,clientX-startX,clientY-startY)
                context.stroke()
                // if(this.type=="stroke"){
                //     context.stroke();
                // }else if(this.type=="fill"){
                //     context.fill();
                // }
                break;
            case 'circle':
                //画圆
                var r=Math.sqrt(Math.pow(startX-clientX,2)+Math.pow(startY-clientY,2))
                context.beginPath();
                context.arc(startX,startY,r,0,2*Math.PI);
                context.stroke();

                // context.beginPath();   
                // context.ellipse(startX,startY,clientX,clientY,0,0,2*Math.PI);            
                // context.stroke();

                break;
            case 'arrows':
                // drawLineArrow(context,clientX, clientY,startX,startY)
                
                context.lineCap="butt";
                jiantou(context,clientX, clientY,startX,startY,20,10)
      
                break;
            case 'eraser':
                context.lineCap="round";
                context.clearRect(clientX-context.lineWidth/2,clientY-context.lineWidth/2,context.lineWidth,context.lineWidth);
                break;
            default:
                break;
        }
        

        prevX = ev.clientX
        prevY = ev.clientY
    }

    let touchEnd = (ev)=> {
        if(!ifDraw) return
        ifDraw = false
        stepArr.push(context.getImageData(0, 0, oCanvas.width, oCanvas.height))
        scop.component.setState({
            canClear:true
        })
        context.stroke()
        ev.preventDefault()
    }

    function activate() {
        oCanvas.addEventListener('touchstart', touchStart, false)
        oCanvas.addEventListener('touchmove', touchMove, false)
        oCanvas.addEventListener('touchend', touchEnd, false)

        oCanvas.addEventListener('mousedown', touchStart, false)
        oCanvas.addEventListener('mousemove', touchMove, false)
        oCanvas.addEventListener('mouseup', touchEnd, false)
        oCanvas.addEventListener('mouseleave', touchEnd, false)
    }

    function dispose() {
        oCanvas.removeEventListener('touchstart', touchStart, false);
        oCanvas.removeEventListener('touchmove', touchMove, false);
        oCanvas.removeEventListener('touchend', touchEnd, false);

        oCanvas.removeEventListener('mousedown', touchStart, false);
        oCanvas.removeEventListener('mousemove', touchMove, false);
        oCanvas.removeEventListener('mouseup', touchEnd, false);
        oCanvas.removeEventListener('mouseleave', touchEnd, false);
    }

}

/**
 * 
 * @param {*canvas context 对象} ctx 
 * @param {*起点横坐标} fromX 
 * @param {*起点纵坐标} fromY 
 * @param {*终点横坐标} toX 
 * @param {*终点纵坐标} toY 
 * 以下注释以终点在坐标第一象限内，且方向为右上方
 */

 function jiantou(ctx,fromX, fromY, toX, toY,theta,headlen){
    const {lineWidth} = ctx

    if(lineWidth===2){
        theta = 16
    }else if(lineWidth===4){
        theta = 16  
    }else{
        theta = lineWidth*4>30?30:lineWidth*4
    }
    headlen = lineWidth*4
    var angle = Math.atan2(fromY - toY, fromX - toX) * 180 / Math.PI,
        angle1 = (angle + theta) * Math.PI / 180,
        angle2 = (angle - theta) * Math.PI / 180,
        topX = headlen * Math.cos(angle1),
        topY = headlen * Math.sin(angle1),
        botX = headlen * Math.cos(angle2),
        botY = headlen * Math.sin(angle2)

    ctx.save()
    ctx.beginPath()

    var arrowX = fromX - topX,
        arrowY = fromY - topY

    ctx.moveTo(arrowX, arrowY)
    ctx.moveTo(fromX, fromY)
    ctx.lineTo(toX, toY)
    arrowX = toX + topX
    arrowY = toY + topY
    ctx.moveTo(arrowX, arrowY)
    ctx.lineTo(toX, toY)
    arrowX = toX + botX
    arrowY = toY + botY
    ctx.lineTo(arrowX, arrowY)
    ctx.stroke()
    ctx.restore()
}


const CanvasDrawer = new initCanvasDraw()

export default CanvasDrawer