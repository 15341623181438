import React from "react"
import { ConfigProvider, theme,Button } from "antd"
import app from "./src/app.js"
import { withTranslation } from "react-i18next"
import { StyleProvider } from '@ant-design/cssinjs';

import Direction from "../../components/direction/direction.jsx"
import LoadingBar from "../../components/loadingBar/loadingBar.jsx"
import GifLoading from "../../components/gifLoading/gifLoading.jsx"
import SliderCom from "../../components/slider/slider.jsx"
import Inform from "../../components/inform/inform.jsx"
import Draw from "../../components/draw/draw.jsx"
import Mousemove from "../../components/mousemove/mousemove.jsx"
import Collect from "../../components/collect/collect.jsx"
import History from "../../components/history/history.jsx"

import Iframe from "../../components/iframe/iframe.jsx"

// import Header from "../../components/header/header.jsx"
import SearchComponent from "../../components/search/search.jsx"
import InteractHelp from "../../components/interactHelp/interactHelp.jsx"
import zhCN from "antd/es/locale/zh_CN"
import enUS from "antd/locale/en_US"
import { net, isDev,isAndroid,actionCode,muscleId,is3dbody,isPc} from "./config.js"
import "./src/load.js"
import "./index.less"
import "./common.less"
import allObj_cn from './src/allObj'
import allObj_en from './src/allObj_en'

app.net = net
app.isAndroid = isAndroid
app.isExportGif = isDev?false:!!localStorage.getItem("isExportGif")



class Index extends React.Component{
    state = {
        ifDraw: false,
        //镜像功能选择
        jing: false,
        fullScreen: false,
        //当前选中的模型的名字
        selectName: null,
        //显示右边内容
        right: false,
        showRightBt: false,
        dark: false,
    }

    componentDidMount() {
        const { t } = this.props
        app.t = t
        app.indexComponent = this
        //初始化webgl
        app.init(this.state.webglDom)
        //开发或者3dbody部署状态不用等待app返回langage  不然则需要等待
        if(isDev||is3dbody||isPc){
            this.initLoad()
        }
    }
    //加载模型之后建立tree菜单 并初始化需要第一显示的
    initLoad=()=>{
        let allObj
        if(app.lang==='cn'){
            allObj = allObj_cn
        }else{
            allObj = allObj_en
        }
        app.buildAllXmlGroup(app.scene,allObj)
        app.loadMain(()=>{
            //加载结束之后如果有搜索就展示对应的搜索
            if(muscleId){
                app.searchComponent.muscleClick(muscleId)
            }
            if(actionCode){
                app.handleActionClick(actionCode,true)
            }
        })
    }


    toggleJing = () => {
        const { jing } = this.state
        this.setState({
            jing: !jing,
        })
        app.jingxiang = !jing
        app.renderer.domElement.style.transform = `scaleX(${jing ? "1" : "-1"})`
    }

    toggleRight = (flag) => {
        if (flag !== undefined) {
            this.setState({
                right: flag,
            })
        } else {
            this.setState({
                right: !this.state.right,
            })
        }
        setTimeout(() => {
            app.windowResize()
        })
    }

    darkClick = () => {
        const { dark, webglDom } = this.state
        webglDom.style.background = dark ? "#ffffff" : "#212121"
        localStorage.setItem("bgColor", dark ? "#ffffff" : "#212121")
        localStorage.setItem("dark", !dark)
    }

    render() {
        const { right, selectName, dark } = this.state
        const {i18n} = this.props
        return (
            <ConfigProvider
                theme={{
                    algorithm: dark?theme.darkAlgorithm:theme.defaultAlgorithm,
                    token: {colorPrimary: "#ffb100"} }}
                locale={i18n.language==='en'?enUS:zhCN}>

                <StyleProvider hashPriority="high">
                    <div className={dark? "dark webgl-wrap showRight" : "webgl-wrap showRight"}>
                        <div className={right? "contain-wrap showRight" : "contain-wrap"}>
                            <SearchComponent />
                            <div className="right">
                                <div className="webgl" id="webgl">
                                    <div ref={(el) => (this.state.webglDom = el)} id="webglDom">
                                        <Mousemove />
                                    </div>
                                    <Draw />
                                    <SliderCom />
                                    <Collect />
                                    <History/>
                                    <div className="name-wrap">
                                        {selectName ? app.GetChinese(selectName) : null}
                                    </div>
                                    <Direction />

                                    <InteractHelp/>
                                </div>
                            </div>

                            <Inform right = {right}/>
                        </div>

                        <LoadingBar />

                        <GifLoading />
                        <Iframe />
                    </div>

                </StyleProvider>

            </ConfigProvider>
        )
    }
}

export default withTranslation()(Index)





        // app.handleHashChange=(hash)=>{
        //     if(hash&&hash.indexOf('=')!==-1){
        //         hash = hash.slice(1,hash.length)
        //         let arr = hash.split('=')
        //         if(arr.length!==2) reutrn
        //         let type = arr[0]
        //         let target = arr[1]
        //         //肌肉点击
        //         if(type==='mesh'){
        //             let mesh = app.scene.getObjectByName(target)
        //             mesh&& app.clickOption(mesh,true)
        //         //动作
        //         }else if(type==='action'){
        //             app.handleActionClick(target,true)
        //         }
        //     }
        // }
        // window.addEventListener('popstate',(e)=>{
        //     let hash = e.currentTarget.location.hash
        //     if(hash){
        //         app.handleHashChange(hash)
        //     }else{
        //         alert(0)
        //     }
        // })