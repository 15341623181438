//import {  Slider } from 'antd-mobile';
import React from 'react'
import { Spin} from "antd"
import { connect } from "react-redux"
import { Button,Divider,Popup,List,Toast,DotLoading,Space} from "antd-mobile"
import app from '../../pages/home/src/app.js'
import { DownOutline,StarOutline,StarFill,CloseOutline,RightOutline,LeftOutline } from 'antd-mobile-icons'
import { GifOutlined } from '@ant-design/icons'

import { withTranslation } from "react-i18next"
import {isPc,is3dbody} from '../../pages/home/config.js'
import Track from './track.jsx'
import './progress.less'

let camareInform
@connect(
    // 你要state什么属性放到props里
    (state) => ({
        playing: state.PlayingReducer,
    })
)
class Progress extends React.Component {
    state = {
        show:false,
        num:0,
        //动作名字
        data:null,
        fixationCheck:true,
        error:null,
        playError:false,
        unfold:true,
        showSlider:false,
        existActive:false,
        showActiveMuscle:true,
        clearColor:false,
        staticPos:false,

        targetDye:true,
        synergistDye:true,
        stabilizersDye:true,
        antagonistDye:true,
        //辅助肌稳定肌等列表
        showActivePopup:false,
        activeList:[],
        activePopupTitle:'',

        showDetail:isPc?true:false,
        //播放动作的时候协同肌等点击
        clickMuscle:null,
    }

    componentDidMount() {
        app.progressComponent = this
    }


    playPause =()=>{
        if(app.playing){
            if(app.playing.isRunning()){
                app.playing.paused = true
                this.setState({
                    stop:true
                })
            }else{
                this.setState({
                    stop:false
                })
                app.playing.paused = false 
            }
            if(app.equipPlaying){
                if(app.equipPlaying.isRunning()){
                    app.equipPlaying.paused = true
                }else{
                    app.equipPlaying.paused = false 
                }
                //app.sceneEquip.clear()
            }
        }
    }

    playErrorFn=()=>{
        const { playError } = this.state
        this.setState({
            playError:!playError
        })
    }

    handleCollectClick=()=>{
        //如果登录过  
        if(!!app.token){ 
            const {data} = this.state
            const { playing} = this.props
            console.log(playing)
            if(data.isCollect){
                app.removeCollect(playing.code,(data)=>{
                    data.isCollect = false
                    this.setState({
                        data:data
                    })
                    app.updatePlaying(data)
                },()=>{})
            }else{
                app.addCollect(playing.code,(data)=>{
                    data.isCollect = true
                    this.setState({
                        data:data
                    })
                    app.updatePlaying(data)
                },()=>{})
            }
        }else{
            app.iframeComponent.showLogin()
            console.log('未登录')
        }
    }

    assistClick=(item)=>{
        console.log(item)
        app.lookAtSkinMesh(item)
        this.setState({showActivePopup:false})
        app.clickOption(item)
    }

    switchAssistMesh=(arr,flag)=>{
        arr.map(item=>item.visible=flag)
    }

    resetPlaying=()=>{
        const {playingItem} = app
        const {t} = this.props
        console.log(playingItem)
        if(playingItem){
            app.handleActionClick(playingItem.code)
        }else{
            Toast.show({ content:t('progress.warningSelectAction')})
        }
    }

    handleClearColor=()=>{
        const {clearColor} = this.state
        this.setState({
            clearColor:!clearColor
        })
        app.toggleDyeRelatedMesh(clearColor)
    }
    showActivePopupFn=(arr,activePopupTitle)=>{
        console.log(activePopupTitle)
        this.setState({
            showActivePopup:true,
            activeList:arr,
            activePopupTitle:activePopupTitle
        })
    }

    closePanel=()=>{
        this.setState({
            show: false,
            data:null,
            clickMuscle:null,
        })
        app.DirectionComponent.setState({
            hideLayerChange:false
        })  
        app.sliderComponent.handeLayerChange(null,true)
        if(app.playing){
            app.resetAllTempOption()
        }
        app.stopPlayAction()
        app.playingNow = false
        app.muscleOption = false
    }

    startAction=()=>{
        this.setState({
            show: true
        })
        app.DirectionComponent.setState({
            hideLayerChange:true
        })  
        app.playingNow = true
        app.muscleOption = true
        app.panel.snapTo(1)
        app.muscleComponent.setPanel(false)
        app.muscleComponent.show(false)
    }

    setClickMuscle=(mesh)=>{
        console.log(mesh)
        this.setState({clickMuscle:mesh})
    }
    clickMuscleConfirm=()=>{
        const {clickMuscle} =this.state
        this.closePanel()
        setTimeout(() => {
            clickMuscle && app.clickOption(clickMuscle,null,null,true)
        })
    }


    handleGifClick = ()=>{
        const {t} = this.props
        if(app.logo){
            app.logo.visible = true
        }else{
            if(isPc){
                //绘制名字
                let sprite = app.makeTextSprite(is3dbody?'3Dbody':'Muscle1')
                sprite.position.x = app.controls.target.x+1
                sprite.position.y = app.controls.target.y
                sprite.position.z = app.controls.target.z
                sprite.sprite = true
                app.logo = sprite
                app.scene.add(sprite)
                console.log(sprite)
            }
        }
        if(!app.playing){
            Toast.show({ content:t('progress.warningSelectAction')})
            return 
        }
        if(app.isExportGif){
            camareInform = [{
                position:`${app.camera.position.x},${app.camera.position.y},${app.camera.position.z}`,
                target:`${app.controls.target.x},${app.controls.target.y},${app.controls.target.z}`
            }]
            let w = 256,
                h = 144
            app.renderer.setSize(w, h)
            app.camera.aspect = w / h
            app.camera.updateProjectionMatrix()
            app.controls.update()

            if (app.allMeshReference['mats']) {
                app.allMeshReference['mats'].visible = false
            }
        }else{
            let w = window.innerWidth/2,
            h = window.innerHeight/2
            app.renderer.setSize(w, h)
            app.camera.aspect = w / h
            app.camera.updateProjectionMatrix()
            app.controls.update()
        }
        if(!app.playing.isRunning()){
            app.trackComponent.playPause()
        }
        window.gifObj = new GIF({
            workers: 10,
            workerScript: 'static/build/gif/gif.worker.js',
            quality: 100
        })
        app.gifLoadingComponent.setState({
            show:true,
            tip:t('progress.gifGenerated')
        })
        //进度条归零
        app.trackComponent.sliderChange(0)
        app.recordGif = true
        app.playing._loopCount=-1
        if(app.equipPlaying){
            app.equipPlaying._loopCount=-1
        }
        let timer = setInterval(() => {
            app.renderer.render(app.scene, app.camera)
            window.gifObj.addFrame(app.renderer.domElement, {copy: true, delay: 42})
        }, 42)
        window.gifObj.on('progress', function(num) {
            app.gifLoadingComponent.setState({
                tip:`${t('progress.gifGenerated')}+${parseInt(num*100)}%`
            })
        })
        window.gifObj.on('finished', function(blob) {
            if(app.isExportGif){
                app.cutScreen()
                let a = document.createElement('a')
                a.style.display ='none'
                let event = new MouseEvent('click')
                a.download = app.playing._clip.name+'.json'
                const blob = new Blob([JSON.stringify(camareInform)])
                a.href = URL.createObjectURL(blob)
                a.dispatchEvent(event)
            }

            let obj_url = URL.createObjectURL(blob)
            let a = document.createElement('a')
            let event = new MouseEvent('click')
            let num = Math.floor(Math.random()*100000)
            if(app.isExportGif){
                a.download = app.playing._clip.name
            }else{
                a.download = is3dbody?'3Dbody-':'Muscle1-'+num
            }
            a.href = obj_url
            a.dispatchEvent(event)

            app.trackComponent.playPause()
            //释放内存
            window.gifObj.frames.length = 0
            window.gifObj.imageParts.length = 0
            window.gifObj.freeWorkers.forEach(w => w.terminate())
            delete window.gifObj 
            app.gifLoadingComponent.setState({
                show:false
            })
            app.logo.visible = false
            app.windowResize()
        })
        let time = parseInt(app.playing._clip.duration/app.playing.timeScale*1000)*2
        setTimeout(() => {
            clearInterval(timer)
            app.recordGif = false
            app.trackComponent.playPause()
            window.gifObj.render()
        }, time)
    }


    render() {
        const {show,data,fixationCheck,existActive,targetDye,synergistDye,stabilizersDye,antagonistDye,showDetail,
            clearColor,staticPos,showActivePopup,activeList,activePopupTitle,clickMuscle} = this.state
        const {t} = this.props
        return (<>{
                    show?<div className='progress-component-wrap'>

                        {
                            data?<div className='action-name-panel'>
                                {
                                    clickMuscle?<div onClick={this.clickMuscleConfirm} className="tap-change">
                                        <Button size='small' shape="rounded">
                                            <Space style={{'--gap':'6px'}}><span>{clickMuscle.dataParent.name}</span><RightOutline /> </Space>
                                        </Button>
                                    </div>:null
                                }

                                <div className='top-name-wrap'>
                                    <Button className='play-close-bt' fill='none' onClick={()=>{
                                        this.closePanel()
                                        if(app.selectMuscle){
                                            app.clickOption(app.selectMuscle)
                                            app.muscleComponent.setState({
                                                pathname:'inform'
                                            })
                                        }
                                    }}>
                                        <CloseOutline/>
                                    </Button>

                                    <Button onClick={()=>{
                                        this.setState({showDetail:!showDetail})
                                    }} className='action-name-text' fill='none'>
                                        <span className={showDetail?'playing-name-toggle-svg rotate':'playing-name-toggle-svg'}>
                                            <RightOutline />
                                        </span>
                                        <span className='playing-name-title'>{data.name}</span>
                                    </Button>

                                    <div className={showDetail?"show-note":"show-note hidden"} >
                                    {
                                        data.description?<div className='show-note-text' dangerouslySetInnerHTML={{ __html: data.description }} />:null
                                    }
                                    </div>
                                    <Divider className='playing-name-divider'></Divider>
                                </div>

                                <div className='playing-fn-bt-wrap'>
                                    <div className='playing-fn-bt-wrap-left'>
                                        <div className='playing-fn-bt-wrap-left-inner'>
                                        <div className='playing-fn-bt-item collect-bt-wrap'>
                                                <Button size='mini' fill='none' shape='rounded' onClick={this.handleCollectClick}>
                                                    { data.isCollect?<StarFill/>:<StarOutline/>}
                                                </Button>
                                            </div>
                                            <div className='playing-fn-bt-item reset-playing-wrap'>
                                                <Button size='mini' shape='rounded' fill='none' onClick={this.resetPlaying}>{t('common.reset')}</Button>
                                            </div>
                                            {
                                                data.fixation?<div className='playing-fn-bt-item fixation-bt-wrap'>
                                                    <Button size='mini' shape='rounded' fill={fixationCheck?'solid':'outline'} onClick={()=>{
                                                        app.fixedObj&&(app.fixedObj.visible=!fixationCheck)
                                                        this.setState({fixationCheck:!fixationCheck})
                                                    }}>{data.fixation} </Button>
                                                </div>:null
                                            }
                                            {
                                                isPc?<div className='playing-fn-bt-item gif-bt-wrap'>
                                                    <Button size='mini' shape='rounded' fill='none' onClick={this.handleGifClick}>
                                                        <GifOutlined />
                                                    </Button>
                                                </div>:null
                                            }
                                            
                                        </div>
                                    </div>

                                    <div className='playing-fn-bt-wrap-right'>
                                        <Track data={data} staticPos={staticPos}/>
                                    </div>
                                    
                                </div>
                                {
                                    existActive?<div className='assist-bt-wrap'>

                                        {
                                            app.zhudongArr.length?<div className='assist-bt-item'>
                                                <Button  fill='none' size='mini'  shape='rounded'
                                                className={targetDye?'target-assist-bt dye':'target-assist-bt'}
                                                    onClick={()=>{
                                                        this.setState({targetDye:!targetDye})
                                                        this.switchAssistMesh(app.zhudongArr,!targetDye)}
                                                    }>
                                                    {t('progress.target')}
                                                </Button>
                                                <Divider direction='vertical' />
                                                <Button size='mini' className='assist-toggle-bt' fill='none'
                                                    onClick={()=>{this.showActivePopupFn(app.zhudongArr,t('progress.target'))}}>
                                                        <DownOutline />
                                                </Button>
                                            </div>:null
                                        }

                                        {
                                            app.xietongArr.length?<div className='assist-bt-item'>
                                                <Button  fill='none' size='mini'  shape='rounded'
                                                className={synergistDye?'synergist-assist-bt dye':'synergist-assist-bt'}
                                                onClick={()=>{
                                                    this.setState({synergistDye:!synergistDye})
                                                    this.switchAssistMesh(app.xietongArr,!synergistDye)}
                                                }>
                                                    {t('progress.synergist')}
                                                </Button>
                                                <Divider direction='vertical' />
                                                <Button size='mini' className='assist-toggle-bt' fill='none'
                                                    onClick={()=>{this.showActivePopupFn(app.xietongArr,t('progress.synergist'))}}>
                                                        <DownOutline />
                                                </Button>
                                            </div>:null
                                        }

                                        {
                                            app.wendingArr.length?<div className='assist-bt-item'>
                                                <Button  fill='none' size='mini'  shape='rounded'
                                                className={stabilizersDye?'stabilizers-assist-bt dye':'stabilizers-assist-bt'}
                                                onClick={()=>{
                                                    this.setState({stabilizersDye:!stabilizersDye})
                                                    this.switchAssistMesh(app.wendingArr,!stabilizersDye)}
                                                }>
                                                    {t('progress.stabilizers')}
                                                </Button>
                                                <Divider style={{}} direction='vertical' />
                                                <Button size='mini' className='assist-toggle-bt' fill='none'
                                                    onClick={()=>{this.showActivePopupFn(app.wendingArr,t('progress.stabilizers'))}}>
                                                        <DownOutline />
                                                </Button>
                                            </div>:null
                                        }

                                        {
                                            app.jiekangArr.length?<div className='assist-bt-item'>
                                                <Button  fill='none' size='mini'  shape='rounded'
                                                className={antagonistDye?'antagonist-assist-bt dye':'antagonist-assist-bt'}
                                                onClick={()=>{
                                                    this.setState({antagonistDye:!antagonistDye})
                                                    this.switchAssistMesh(app.jiekangArr,!antagonistDye)}
                                                }>
                                                    {t('progress.antagonist')}
                                                </Button>
                                                <Divider direction='vertical' />
                                                <Button size='mini' fill='none' shape='rounded' className='assist-toggle-bt'
                                                onClick={()=>{this.showActivePopupFn(app.jiekangArr,t('progress.antagonist'))}}>
                                                        <DownOutline />
                                                </Button>
                                            </div>:null
                                        }
                                    </div>:null
                                }
                            </div>:<div className='action-detail-wrap loading'><Spin color='primary'/></div>
                        }

                        <Popup
                            visible={showActivePopup}
                            onMaskClick={() => {
                                this.setState({showActivePopup:false})
                            }}
                            maskStyle={{
                                background:'rgba(0,0,0,0.1)',
                                touchAction: 'none',
                            }}
                            // position={isPc?'left':'bottom'}
                            bodyStyle={{
                                borderTopLeftRadius: '8px',
                                borderTopRightRadius: '8px',
                                minHeight: window.innerWidth>500?'300px':'10vh',
                                maxWidth:'520px',
                            }}>
                            <div className='active-popup-inner'>
                                <List header={activePopupTitle}>
                                    {
                                        activeList.map((item,index)=>{
                                            return <List.Item arrow={false} onClick={this.assistClick.bind(this,item)}
                                            key={index}>{ item.dataParent.name}</List.Item>
                                        })
                                    }
                                </List>
                            </div>
                        </Popup>

                    </div>:null
                }
            </>

        )
    }
}

export default withTranslation()(Progress)


