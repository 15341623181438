
import { Spin,Alert,Button } from 'antd';
import React from 'react'
import app from '../../pages/home/src/app.js'
import './gifLoading.less'

class GifLoading extends React.Component {

    state = {
        //是否显示进度条
        show: false,
        //进度条数值
        tip: 0,
    }
    updataLoading(tip) {
        this.setState({
            tip: tip
        })
    }
    componentDidMount() {
        app.gifLoadingComponent = this
    }
  
    render() {
        const {tip,show} = this.state
        return (
            <div className = {show?'gif-loading-wrap':'gif-loading-wrap hide'}>
                <Spin size='large' tip={tip}>
                    <div className='gif-loading-cennter'></div>
                </Spin>
            </div>
        )
    }
}

export default GifLoading