import React from "react"
import ActionItem from "../actionItem/actionItem.js"
import "./actionwrap.less"

export default function ActionWrap (props) {
    const { data} = props
    return (
        <div className="no-instrument-action-wrap">
           <ul className="action-list">
                {data&&data.map((item, index) => {
                        return (
                            <ActionItem
                                widthStr={"calc(33% - 20px)"}
                                data={item}
                                key={index}
                            />
                        )
                    })}
            </ul>
        </div>
    )
    
}


