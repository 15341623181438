import React from 'react'
import app from '../../pages/home/src/app.js'
import { EyeInvisibleOutlined,PlusOutlined,HistoryOutlined,
    MinusOutlined,UndoOutlined,CloseOutlined,AimOutlined} from '@ant-design/icons';
    
import { 
    DownOutline,
    UpOutline,
    StarOutline,
    SearchOutline,
} from 'antd-mobile-icons'
import { Button} from "antd"
import { Divider} from "antd-mobile"
import { withTranslation } from "react-i18next"
import './direction.less'

const initHasClickZoomIn = localStorage.getItem('hasClickZoomIn')
const initHasClickHistory = localStorage.getItem('hasClickHistory')
const initHasClickCollect = localStorage.getItem('hasClickCollect')

class Direction extends React.Component {

    state = {
        draw: false,
        slice:false,
        pan:false,
        //当前选中的模型的名字
        show:false,
        enableUndo:true,
        fullScreen:false,
        zoomIn:true,
        hasClickZoomIn:!!initHasClickZoomIn ? true : false,
        showZoomBt:false,
        jingxiang:false,
        expand:false,
        hideLayerChange:false
    }

    componentDidMount() {
        app.DirectionComponent = this  
        document.addEventListener('click',()=>{
            if(this.state.slice){
                this.setState({
                    enableUndo:app.sliceArr.length?false:true
                })
            }
        })
    }
    
    //画板开关
    toggleDraw=()=>{
        const { draw,slice} = this.state;
        const {t} = this.props
        if(slice){
            // message.info(t('direction.closeHideSelect'))
            this.toggleSlice()
        }
        this.setState({
            draw: !draw,
            show:!draw
        })
        app.drawerComponent.canvasDrawer.beginDraw(!draw)
        //打开画笔的时候隐藏信息框
        if(!draw){
            app.panel.snapTo(1)
            app.muscleComponent.show(false)
            app.muscleComponent.setPanel(false)
        }
    }

    toggleSlice=()=>{
        const {slice} = this.state
        this.setState({
            slice:!slice
        })
        app.slice = !slice
        if(slice){
            app.sliceArr = []
        }
    }

    togglePan = ()=>{
        const {pan} = this.state
        this.setState({
            pan:!pan
        })
        app.controls.forcePan = !pan
    }
    closeSlice=()=>{
        this.toggleSlice()
    }
    resetSlice=()=>{
        if(app.sliceArr.length){
            app.sliceArr.map(item=>item.visible = true)
            app.sliceArr = []
        }
        this.setState({
            enableUndo:false     
        })
    }
    undoSlice=()=>{
        if(app.sliceArr.length){
            app.sliceArr[app.sliceArr.length-1].visible = true
            app.sliceArr.splice(app.sliceArr.length-1,1)
        }
        if(app.sliceArr.length<1){
            this.setState({
                enableUndo:false     
            })
        }
    }

    toggleJing=()=>{
        const {jingxiang} = this.state
        this.setState({
            jingxiang:!jingxiang
        })
        app.jingxiang = !jingxiang
        app.renderer.domElement.style.transform = `scaleX(${jingxiang?'1':'-1'})`
    }
    
    focusTap=()=>{
        const { hasClickZoomIn } = this.state
        const {zoomIn} = this.state
        this.setState({
            zoomIn:!zoomIn
        })
        app.zoomIn = !zoomIn
        if(app.currentClickObj){
            app.lookAtSelectObj([app.currentClickObj])
        }
        if (!hasClickZoomIn) {
            this.setState({
                hasClickZoomIn: true
            })
            localStorage.setItem('hasClickZoomIn', 'true')
        }
    }

    render() {
        const {show,draw,slice,pan,enableUndo,expand,hideLayerChange,jingxiang} = this.state
        const {t} = this.props
        return (
            <div className = {show?'nav-tool-wrap hide':'nav-tool-wrap'}>
        
                <div className={slice?'slice-tip-wrap active':'slice-tip-wrap'}>
                    <div onClick = {this.undoSlice} className='slice-tip-item slice-tip-undo'>
                        <Button disabled={enableUndo?true:false} type='text'>
                            <UndoOutlined />
                        </Button>
                    </div>
                    <div className='slice-tip-item slice-tip-reset'>
                        <Button  onClick={this.resetSlice}  disabled={enableUndo?true:false} type='text'>
                            {t("direction.reset")}
                        </Button>
                    </div>
                    <div onClick={this.closeSlice} className='slice-tip-item slice-tip-close'>
                        <Button type='text'>
                            <CloseOutlined />
                        </Button>
                    </div>
                </div>

                <div className='tool-wrap tool-history-wrap'>
                    {
                        !hideLayerChange?<>
                            <Button className='tool-item search-bt' onClick={()=>{
                                app.searchComponent.showPanel()
                                app.searchComponent.startSearching()
                            }}>
                                <SearchOutline />
                            </Button> 
                            <Button className='tool-item history-bt' onClick={()=>{
                                if(!app.token){
                                    app.iframeComponent.goLoginPage()
                                }else{
                                    app.historyComponent.updateActionHistory()
                                    app.historyComponent.showPanel()
                                }
                            }}>
                                <HistoryOutlined />
                            </Button>
                            <Button className='tool-item' onClick={()=>{
                                if(!app.token){
                                    app.iframeComponent.goLoginPage()
                                }else{
                                    app.collectComponent.handleShowPanel()
                                    app.collectComponent.resetCollect()
                                }
                            }}>
                                <StarOutline />
                            </Button>
                            <Divider style={{marginBottom:0,marginTop:10}}/>
                        </>:null
                    }

                    <div className={expand?'expand-items-wrap expand':'expand-items-wrap'}>
                        <Button className={slice?'tool-item slice active':'tool-item slice'} onClick={this.toggleSlice}>
                            <EyeInvisibleOutlined />
                        </Button>
                        <Button className={draw?'tool-item draw active':'tool-item draw'} onClick={this.toggleDraw}></Button>
                        <Button className='tool-item cutScreen' onClick={app.cutScreen}></Button>
                        <Button className={jingxiang?'tool-item jingxiang active':'tool-item jingxiang'} onClick={this.toggleJing}></Button>
                    </div>

                    <Button className='tool-item' onClick={()=>{this.setState({expand:!expand})}}>
                        {expand?<UpOutline />:<DownOutline />}
                    </Button>
                    {/* resetCamera */}
                    <Button className='tool-item ' onClick={app.centerScene}>
                         <AimOutlined />
                    </Button>
                    {
                        !hideLayerChange?<>
                            <Button className='tool-item' onClick={()=>{
                                app.sliderComponent.tapLayer('sub')
                            }}>
                                <PlusOutlined />
                            </Button>
                            <Button className='tool-item' onClick={()=>{
                                app.sliderComponent.tapLayer('add')
                            }}>
                                <MinusOutlined />
                            </Button>
                        </>:null
                    }

                 </div>

            </div>
            
        )
    }
}

export default withTranslation()(Direction)